import Keycloak from "keycloak-js";
import env from "react-dotenv";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: env.REACT_APP_ACC_AUTH_URL || "https://is.agriness-dev.com/auth/",
  realm: env.REACT_APP_KEYCLOACK_REALM || "agriness-dev",
  clientId: env.REACT_APP_KEYCLOACK_RESOURCE || "myaccount-react",
});

export default keycloak;
