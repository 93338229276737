import React, { useEffect, useState } from "react";
import {
  DialogStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogContentTextStyled,
  DialogTitleStyled,
  Icon,
} from "./styles";
import Button from "../Button";
import check from "../../Images/check.png";
import errorIcon from "../../Images/error.svg";
import { useTranslation } from "react-i18next";

type Props = {
  success: boolean;
  error: boolean;
  labelSuccess: string;
  labelSuccessOption?: any;
  labelError: string;
};

const Feedback = ({
  success,
  error,
  labelSuccess,
  labelSuccessOption,
  labelError,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [localError, setLocalError] = useState(false);
  const [localSucces, setLocalSucces] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (success || error) {
      setOpen(true);
      setLocalSucces(success);
      setLocalError(error);
    }
  }, [success, error]);

  const handleClose = () => {
    setOpen(!open);
  };

  const labelMain = (
    <span>
      {" "}
      {`${labelSuccess}`}
      <div></div> {`${labelSuccessOption ? labelSuccessOption : ""}`}{" "}
    </span>
  );
  return (
    <DialogStyled
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleStyled id="alert-dialog-title">{""}</DialogTitleStyled>
      <DialogContentStyled>
        <Icon
          src={localSucces ? check : localError ? errorIcon : ""}
          alt={localSucces ? "success" : localError ? "error" : ""}
        />
        <DialogContentTextStyled id="alert-dialog-description">
          {localSucces ? labelMain : localError ? labelError : ""}
        </DialogContentTextStyled>
        <Button
          variant="primary"
          size="medium"
          label={
            localSucces
              ? t("modalButtonSuccess")
              : localError
              ? t("modalButtonError")
              : ""
          }
          onClick={handleClose}
        ></Button>
      </DialogContentStyled>
      <DialogActionsStyled></DialogActionsStyled>
    </DialogStyled>
  );
};

export default Feedback;
