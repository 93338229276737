type Variant = "primary" | "secondary" | "common" | "feedback" | "backgrounds";

type IVariantColor = {
  key: Variant;
  value: IColor[];
};

type IColor = {
  key: string;
  value: string;
};

const variantsColor: IVariantColor[] = [
  {
    key: "primary",
    value: [
      {
        key: "primary",
        value: "#205FD4",
      },
      {
        key: "secondary",
        value: "#063097",
      },
      {
        key: "aux",
        value: "#D4EEFE",
      },
      {
        key: "tertiary",
        value: "#2777f5",
      },
      {
        key: "disabled",
        value: "#f2f2f2",
      },
    ],
  },
  {
    key: "secondary",
    value: [
      {
        key: "primary",
        value: "#FFFFFF",
      },
    ],
  },
  {
    key: "common",
    value: [
      {
        key: "white",
        value: "#fff",
      },
      {
        key: "grey-light",
        value: "#D1D7DB",
      },
      {
        key: "grey-medium",
        value: "#71767A",
      },
      {
        key: "grey-dark",
        value: "#212325",
      },
      {
        key: "grey-dark-less-opacity",
        value: "rgba(33, 35, 37, 0.15)",
      },
    ],
  },
  {
    key: "feedback",
    value: [
      {
        key: "danger",
        value: "#FF2D2D",
      },
      {
        key: "alert",
        value: "#FF8712",
      },
    ],
  },
  {
    key: "backgrounds",
    value: [
      {
        key: "light",
        value: "#F2F2F2",
      },
    ],
  },
];
const getColor = (variant: string, key: string) => {
  return variantsColor
    .find((variantColor) => variantColor.key === variant)
    ?.value.find((color) => color.key === key)?.value;
};

export { getColor };
