import React from "react";
import { ButtonStyled } from "./styles";

type Variant = "primary" | "secondary";
type Size = "small" | "medium" | "large";

export type Props = {
  label: string;
  variant?: Variant;
  size?: Size;
  onClick?: Function;
  disabled?: boolean;
  id?: string;
};

const Button = ({
  label,
  onClick,
  disabled,
  variant = "primary",
  size = "medium",
  id,
}: Props) => {
  return (
    <ButtonStyled
      id={id}
      disabled={disabled}
      onClick={() => onClick?.()}
      variant="contained"
      color={variant}
      size={size}
    >
      {label}
    </ButtonStyled>
  );
};

export default Button;
