import { TextFieldStyled, TouchableFake } from "./styles";
import React, { useCallback } from "react";
import { InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

export type Props = {
  label: string;
  value: string | number ;
  setValue: Function;
  adornment?: JSX.Element;
  onClickAdornmet?: Function;
  applyMask?: Function;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  textId?: string;
};

const InputField = ({
  label,
  adornment,
  value,
  setValue,
  onClickAdornmet,
  applyMask,
  error,
  errorMessage,
  disabled,
  textId
}: Props) => {
  const handleAdornmentClick = useCallback(() => {
    onClickAdornmet?.();
  }, [onClickAdornmet]);

  const handleSetValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;

      setValue(newValue);
    },
    [setValue]
  );

  const handleClearInput = useCallback(() => {
    setValue("");
  }, [setValue]);

  return (
    <TextFieldStyled
      disabled={disabled}
      data-testid={textId}
      error={error}
      helperText={errorMessage}
      value={applyMask && value ? applyMask(value) : value}
      fullWidth
      onChange={handleSetValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TouchableFake
              onClick={value ? handleClearInput : handleAdornmentClick}
            >
              {value && !disabled ? <ClearIcon /> : adornment || <></>}
            </TouchableFake>
          </InputAdornment>
        ),
      }}
      label={label}
      variant={"outlined" as any}
    />
  );
};

export default InputField;
