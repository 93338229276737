import React from "react";
import { RouterLink, RouterLinkNotSelected, Selected } from "./styles";
import { useLocation } from "react-router-dom";
export type Props = {
  name: string;
  path: string;
};

type PropRoute = {
  path: string;
  isSelected: boolean;
  children: JSX.Element;
  name: string;
};

const Router = ({ path, isSelected, children, name }: PropRoute) => {
  if (isSelected) {
    return (
      <RouterLink to={path}>
        {name} {children}
      </RouterLink>
    );
  } else {
    return (
      <RouterLinkNotSelected to={path}>
        {name}
        {children}
      </RouterLinkNotSelected>
    );
  }
};

const NavigationItem = ({ name, path }: Props) => {
  const location = useLocation();

  return (
    <Router
      path={path}
      isSelected={location.pathname === path}
      children={location.pathname === path ? <Selected /> : <> </>}
      name={name}
    />
  );
};

export default NavigationItem;
