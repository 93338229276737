import Button from "@material-ui/core/Button";
import styled from "styled-components";

export const ButtonStyled = styled(Button)`
  color: ${(props) => props.theme.getColor("common", "white")};
  box-shadow: ${(props) => props.theme.shadows[3]};
  transition: none;
  width: 70px;
  font-family: ${(props) => props.theme.getFontFamily()};

  background: linear-gradient(
    to right,
    ${(props) => props.theme.getColor(props.color ?? "primary", "secondary")} 0%,
    ${(props) => props.theme.getColor(props.color ?? "primary", "tertiary")}
      100%
  );

  padding: ${(props) => props.theme.getPaddingByButtonSize(props.size)};
  max-height: 36px;
  &:hover {
    background: linear-gradient(
      ${(props) => props.theme.getColor(props.color ?? "primary", "primary")} 0%,
      ${(props) => props.theme.getColor(props.color ?? "primary", "primary")}
        100%
    );

    box-shadow: ${(props) => props.theme.shadows[5]};
  }

  &:focus {
    background: linear-gradient(
      ${(props) => props.theme.getColor(props.color ?? "primary", "primary")},
      ${(props) => props.theme.getColor(props.color ?? "primary", "primary")}
    );
    border: 4px solid
      ${(props) => props.theme.getColor(props.color ?? "primary", "primary")};

    .MuiButton-label {
      border: none;
    }
  }

  .MuiButton-label {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: 4px solid transparent;
    font-family: OpenSansBold;
  }

  &.Mui-disabled {
    background: ${(props) => props.theme.getColor("primary", "disabled")};
  }
`;
