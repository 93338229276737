import { all, takeLatest } from "redux-saga/effects";
import { SAVE_USER } from "../../Actions/User";
import { SUCCESS, FAILURE } from "../../Actions/Feedback";
import api from "../../Service/api";
import { select, put } from "redux-saga/effects";

function* saveUser(): any {
  const store = yield select();
  try {
    api().put("/user-profile/", store.user.user);
    yield put({ type: SUCCESS });
  } catch (e) {
    console.log(e);
    yield put({ type: FAILURE });
  }
}

export function* watchUserScreen() {
  yield all([takeLatest(SAVE_USER, saveUser)]);
}
