type Type = "small" | "medium" | "large";

type Size = {
  width: string;
  heigth: string;
  margin: string;
  padding: string;
};

type PanelSize = {
  key: Type;
  value: Size;
};

const panelSizes: PanelSize[] = [
  {
    key: "small",
    value: {
      width: "312px",
      heigth: "440px",
      margin: "24px 120px 0 8px",
      padding: "40px 0px",
    },
  },
  {
    key: "medium",
    value: {
      width: "720px",
      heigth: "440px",
      margin: "28px 8px 0 120px",
      padding: "20px 0 0 2px",
    },
  },
  {
    key: "large",
    value: {
      width: "",
      heigth: "",
      margin: "",
      padding: "",
    },
  },
];

const getPanelSize = (type: Type) => {
  return panelSizes.find((panelSize) => panelSize.key === type)?.value ?? {};
};

export { getPanelSize };
