import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

export const SelectStyled = styled(Select)`

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 0;
    min-height: 0;
  }
 
  &.Mui-disabled {
    background: ${(props) => props.theme.getColor("backgrounds", "light")};
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 12px;
    padding: 10px 8px 4px ;
  }
`;

export const MenuItemStyled = styled(MenuItem)``;

export const FormControlStyled = styled(FormControl)`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.getColor("common", "grey-medium")};
    
  }



  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.getColor("feedback", "danger")};
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${(props) => props.theme.getColor("feedback", "danger")};
    }
  }

  .MuiOutlinedInput-root.Mui-disabled {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.getColor("common", "grey-light")};
    }
  }
  .MuiFormHelperText-contained {
    /* margin-left: 0px; */
  }
`;
export const InputLabelStyled = styled(InputLabel)`
  color: ${(props) => props.theme.getColor("common", "grey-medium")};
  font-size: 12px;
  top: 0px;

  &.Mui-error {
    color: ${(props) => props.theme.getColor("common", "grey-medium")};
  }
`;

export const FormHelperTextStyled = styled(FormHelperText)`
  color: ${(props) => props.theme.getColor("common", "grey-medium")};
  font-size: 10px;
  margin-left: 0px;
  &.Mui-error {
    color: ${(props) => props.theme.getColor("feedback", "danger")};
  }
`;
