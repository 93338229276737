import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

interface SelectStyledProps {
  isModule: boolean;
}

export const SelectStyled = styled(Select)<SelectStyledProps>`
  &.Mui-disabled {
    background: ${(props) => props.theme.getColor("backgrounds", "light")};
  }
  .MuiChip-root {
    background: ${(props) => (props.isModule ? "#3ebac2" : "#1976d2")};
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 12px;
    padding: 9px 8px 4px;
    height: 24px;
    min-height: 24px;
  }
`;

export const MenuItemStyled = styled(MenuItem)<SelectStyledProps>`
  .MuiTypography-body1 {
    font-size: 0.75rem;
    font-family: "OpenSansRegular";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
  }

  .MuiCheckbox-root.Mui-checked {
    color: ${(props) => (props.isModule ? "#3ebac2" : "#1976d2")};
  }
  &.Mui-selected {
    div {
      color: ${(props) => (props.isModule ? "#3ebac2" : "#1976d2")};
    }
  }
`;

export const FormControlStyled = styled(FormControl)`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.getColor("common", "grey-medium")};
  }

  .MuiOutlinedInput-root.Mui-error {
    color: ${(props) => props.theme.getColor("feedback", "danger")};
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${(props) => props.theme.getColor("feedback", "danger")};
    }
  }

  .MuiOutlinedInput-root.Mui-disabled {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.getColor("common", "grey-light")};
    }
  }
  .MuiFormHelperText-contained {
    /* margin-left: 0px; */
  }
`;
export const InputLabelStyled = styled(InputLabel)`
  color: ${(props) => props.theme.getColor("common", "grey-medium")};
  font-size: 12px;
  top: 0px;

  &.Mui-error {
    color: ${(props) => props.theme.getColor("common", "grey-medium")};
  }
`;

export const FormHelperTextStyled = styled(FormHelperText)`
  color: ${(props) => props.theme.getColor("common", "grey-medium")};
  font-size: 10px;
  margin-left: 0px;
  &.MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }

  .MuiFormHelperText-contained {
    margin-left: 14px;
    margin-right: 14px;
  }
`;
