import React, { useEffect, useState } from "react";
import LoadingIcon from "../Icons/IconLoading";
import {
  DialogStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from "./styles";

import { useTranslation } from "react-i18next";

type Props = {
  success: boolean;
  error: boolean;
  setStateModal: (value: boolean) => void;
};

const LoadingFeedBack = ({ success, error, setStateModal }: Props) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (success || error) {
      setOpen(true);
    }
    if(success === false) {
      setOpen(false)
    }    
  }, [success, error]);

  const handleClose = () => {
    setStateModal && setStateModal(open);
    setOpen(!open);
  };

  return (
    <DialogStyled
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
      // hideBackdrop 
    >
      <DialogTitleStyled id="alert-dialog-title">{""}</DialogTitleStyled>
      <DialogContentStyled>
        <LoadingIcon title={t("LoadingData")} />
      </DialogContentStyled>
      <DialogActionsStyled></DialogActionsStyled>
    </DialogStyled>
  );
};

export default LoadingFeedBack;
