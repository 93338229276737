import React from "react";
import { Tooltip, TableBody, TableHead, Grid } from "@material-ui/core";
import SelectBox from "../SelectBox";
import Pagination from "@mui/material/Pagination";
import { TableRowProps } from "@material-ui/core/TableRow";
import { TableCellProps } from "@material-ui/core/TableCell";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { shortString } from "../../Utils/formats";
import { useTranslation } from "react-i18next";
import LoadingIcon from "../Icons/IconLoading";
import Arrow from "../../Images/arrow.svg";
import * as S from "./styles";
import iconEmptyData from "../../Images/file-no-data.png";
import SearchtComponent from "../Search";

interface ExtendTableCellProps extends TableCellProps {
  ellipsis?: boolean;
  maxLength?: number;
  copyable?: boolean;
  tooltip?: string;
  tableHeader?: boolean;
  orderTable?: boolean;
  keyOrder?: string;
  subKeyOrder?: string;
  getOrder?: (
    value: string,
    keyOrder: string | undefined,
    subKeyOrder: string | undefined,
    firtsOder: boolean
  ) => void;
}

export const TableCell = (props: ExtendTableCellProps) => {
  const isString = typeof props.children === "string";
  const text = props?.tooltip ?? String(props?.children);
  const { i18n } = useTranslation();

  const [tooltipText, setTooltipText] = React.useState<any>(
    isString ? props.children : ""
  );

  const handleCopy = () => {
    if (props.copyable) {
      navigator.clipboard
        .writeText(text)
        .then(() => setTooltipText(i18n.t("COPIED_KEY")));
    }
  };

  const content = () => (
    <S.TableCell {...props} onClick={handleCopy}>
      {typeof props?.children !== "string" ? (
        props.children
      ) : props?.copyable ? (
        <span>
          <FileCopyIcon style={{ fontSize: 14, marginRight: 4 }} />
          {shortString(text, props?.maxLength || 50)}
        </span>
      ) : props?.orderTable ? (
        <span style={{ display: "flex", cursor: "pointer" }}>
          {props?.children}
          <img alt="arrow" src={Arrow} />
        </span>
      ) : props?.ellipsis ? (
        shortString(text, props?.maxLength || 50)
      ) : (
        props?.children
      )}
    </S.TableCell>
  );

  return props?.tooltip || (props?.ellipsis && isString) ? (
    <Tooltip
      title={tooltipText}
      onOpen={() => setTooltipText(text)}
      placement="top"
      arrow
    >
      {content()}
    </Tooltip>
  ) : (
    content()
  );
};

export const TableRow = (props: TableRowProps) => {
  return <S.TableRowStyle {...props} />;
};

interface TableProps {
  header: React.ReactNode;
  data: any[];
  loading: boolean;
  titleTable?: string;
  renderItems: (item: any, index: number) => React.ReactNode;
  handleChange: (value: string) => void;
  searchFields: string;
  emptyLabel: string;
  count: number;
  pageSize: number;
  getPageSize: (value: number) => void;
  getPage: (value: number) => void;
  icon?: React.ReactNode;
  order?: {
    order: string;
    key: string | undefined;
    subKey: string | undefined;
    firtsOder: boolean;
  };
  titleInBold?: boolean;
}

const CustomTable = ({
  data,
  header,
  renderItems,
  loading,
  titleTable,
  handleChange,
  searchFields,
  count,
  pageSize,
  getPageSize,
  getPage,
  icon,
  order,
  emptyLabel,
  titleInBold = false,
}: TableProps) => {
  const { i18n } = useTranslation();

  const [pageSizeInTalbe, setPageSizeInTable] = React.useState(pageSize);

  const handleChangePageSize = (value: string | number) => {
    !!getPageSize && getPageSize(Number(value));
  };

  const handleGetPage = (value: number) => {
    !!getPage && getPage(value);
  };

  React.useEffect(() => {
    console.log("pageSize", pageSize);
    setPageSizeInTable(pageSize);
  }, [pageSize]);

  return (
    <S.TableContainerStyle>
      <S.SearchContainer>
        {titleTable && (
          <S.TableTitle titleInBold={titleInBold}>{titleTable}</S.TableTitle>
        )}
        <Grid style={{ display: "flex" }}>
          <SearchtComponent
            searchFields={searchFields}
            getSearch={handleChange}
          />
          {!!icon && icon}
        </Grid>
      </S.SearchContainer>
      {loading ? (
        <LoadingIcon title={i18n.t("LoadingData")} />
      ) : (
        <>
          {data.length === 0 ? (
            <S.PanelContainer>
              <S.PanelItem xs={12}>
                <S.EmptyData>
                  <img src={iconEmptyData} alt="Empty Data" />
                  {emptyLabel}
                </S.EmptyData>
              </S.PanelItem>
            </S.PanelContainer>
          ) : (
            <S.containerTable>
              <TableHead>
                <S.TableRowStyle>{header}</S.TableRowStyle>
              </TableHead>

              <TableBody>
                <>
                  {data.map((row: any, index: number) =>
                    renderItems(row, index)
                  )}
                </>
              </TableBody>
            </S.containerTable>
          )}
        </>
      )}
      <S.FooterTable style={{ display: "flex" }}>
        <S.TableFooterStyles>
          <Pagination
            count={Math.ceil(count / pageSize)}
            color="primary"
            size="small"
            onChange={(event, page) => handleGetPage(page)}
          />
        </S.TableFooterStyles>
        <S.ChangeTable>
          <p>
            {i18n.t("labelTotalResults")} {count} -
          </p>
          <p>{i18n.t("LinesPerPage")}</p>
          <SelectBox
            getValue={(value: string | number) => handleChangePageSize(value)}
            initalValue={pageSizeInTalbe}
            values={[
              { label: 5, value: 5 },
              { label: 10, value: 10 },
              { label: 20, value: 20 },
              { label: 40, value: 40 },
              { label: 60, value: 60 },
              { label: 100, value: 100 },
            ]}
          />
        </S.ChangeTable>
      </S.FooterTable>
    </S.TableContainerStyle>
  );
};

export default CustomTable;
