import React from "react";
import * as S from "./styles";

interface Props {
    title?:string
}

const LoadingIcon = ({title}: Props) => {
  return (
    <S.Wrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="104"
        fill="none"
        viewBox="0 0 104 104"
      >
        <circle
          cx="52"
          cy="52"
          r="34.143"
          stroke="#D4EEFE"
          strokeWidth={8}
          transform="rotate(-45 52 52)"
        />
        <path
        id="teste"
          stroke="#205FD4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={8}
          d="M64.43 20.071c-17.517-6.982-37.377 1.557-44.359 19.074-6.982 17.516 1.557 37.376 19.074 44.359"
        />
        <path
          fill="#2A64E8"
          d="M54.87 37.143c-.401 0-.764.24-.92.61L41.445 67.325c-.28.66.205 1.39.92 1.39h6.764c.402 0 .765-.241.921-.611l12.505-29.572c.28-.659-.205-1.39-.92-1.39H54.87z"
        />
      </svg>
      {!!title && <p>{title}</p>}
    </S.Wrapper>
  );
};

export default LoadingIcon;
