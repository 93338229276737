import i18n from "i18next";

export const formatNameLocation = (
  name: string,
  state: string,
  country: string
) => {
  try {
    return `${name}, ${state}, ${country}`;
  } catch (error) {
    return "";
  }
};

export const formatPagination = (
  data: Array<any>,
  numberOfLines: number
): Array<number> => {
  const numberOfKeys = data.length / numberOfLines;
  const ceilNumber = Math.ceil(numberOfKeys);
  const arrayNumbers = Array.from(Array(ceilNumber).keys());
  return arrayNumbers.map((item) => item + 1);
};

export const translateNamesProducts = (name: string) => {
  const productsKeys: any = {
    CORP_DAIRY: i18n.t("CORP_DAIRRY"),
    M4: "M4",
    PRESENCE_APP: i18n.t("PRESENCE_APP"),
    S4: "S4",
    CORP_LAYERS: i18n.t("CORP_LAYERS"),
    L4: i18n.t("P4_LAYERS"),
    P4: i18n.t("P4_BROILER"),
    CORP_POULTRY: i18n.t("CORP_POULTRY"),
    MELHORES_SUINOCULTURA: i18n.t("MELHORES_SUINOCULTURA"),
    CORP_SWINE: i18n.t("CORP_SWINE"),
  };
  try {
    return productsKeys[name].toUpperCase();
  } catch (error) {
    return name;
  }
};

export const translateNameModules = (name: string) => {
  const productsKeys: any = {
    AUDIT: i18n.t("audit"),
    ECONOMIC: i18n.t("economic"),
    THINKING: i18n.t("thinking"),
  };
  try {
    return {
      label: productsKeys[name].toUpperCase(),
      value: name,
    };
  } catch (error) {
    return {
      label: name,
      value: name,
    };
  }
};

export const shortString = (str: string, maxLength = 100) => {
  const isString = typeof str === typeof "";

  if (isString && str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }

  return str;
};
