import styled, { css } from "styled-components";
import media from "styled-media-query";
import { Grid } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TRow from "@material-ui/core/TableRow";
import TCell from "@material-ui/core/TableCell";
import Stack from "@mui/material/Stack";

interface TitleProps {
  titleInBold?: boolean;
}

interface TheadProps {
  tableHeader?: boolean;
}

const wrapperTableModifiers = {
  titleInBold: () => css`
    color: #000000;
  `,
  tableHeaderRow: () => css`
    font-family: OpenSansRegular;
    color: rgb(113, 118, 122);
    font-size: 10px;
    text-align: start;
    border-bottom-color: #d1d7db;
    font-weight: 600;
  `,
};

export const TableContainerStyle = styled(TableContainer)`
  padding-top: 10px;
  margin-bottom: 15px;
`;

export const containerTable = styled(Table)`
  width: 100%;
`;

export const FooterTable = styled.div`
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  justify-content: space-between;
  ${media.lessThan("medium")`
    width: 100%;
    flex-direction: column;
    margin-bottom: 22px;
  `}
`;

export const ChangeTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-family: "OpenSansBold";
    font-size: 10px;
    color: #71767a;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: 4px;
  }
`;

export const TableFooterStyles = styled(Stack)`
  margin-top: 18px;
  li {
    button {
      color: #1c1e1f;
      svg {
        font-size: 1.5rem;
      }
    }
  }
  button {
    font-size: 8px;
    color: rgb(33, 35, 37);
  }
  button.Mui-selected.MuiPaginationItem-page {
    background-color: rgb(212, 238, 254);
    color: rgb(33, 35, 37);
    font-size: 8px;
    padding: 10x;
    font-weight: bold;
    border-radius: 30px;
  }
`;

export const TableCell = styled(TCell)<TheadProps>`
  ${({ tableHeader }) => css`
    font-size: 12px;
    font-family: "OpenSansRegular";
    padding: 3px;
    padding-left: 12px;
    text-align: start;
    white-space: nowrap;
    width: auto;
    max-width: 265px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    border-bottom-color: #f2f2f2;
    @media (max-width: 1700px) {
      max-width: 100%;
    }
    ${!!tableHeader && wrapperTableModifiers.tableHeaderRow()};
  `}
`;

export const SearchContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  ${media.lessThan("medium")`
    display: initial;
  `}
`;

export const TableRowStyle = styled(TRow)`
  text-align: left;
  color: rgb(113, 118, 122);
  font-size: 10px;
`;

export const TableTitle = styled.div<TitleProps>`
  ${({ theme, titleInBold = false }) => css`
    font-family: "OpenSansBold";
    font-size: 12px;
    color: #71767a;
    ${media.lessThan("medium")`
      margin-left: 24px;
    `};
    ${!!titleInBold && wrapperTableModifiers.titleInBold()};
  `}
`;

export const PanelItem = styled(Grid).attrs({ item: true })`
  .table {
    overflow: auto;
  }

  ${media.lessThan("medium")`
    display: flex;
    flex-direction: column;

    .first-input {
      margin-bottom: 15px;
    }
  `}
`;

export const EmptyData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "OpenSansBold";
  ${media.lessThan("medium")`
    margin-left: 0;
  `}
  > img {
    margin-bottom: 20px;
  }
`;

export const PanelContainer = styled(Grid).attrs({ container: true })`
  ${media.greaterThan("medium")`
    padding: 0 1.5rem 0 1.5rem;
  `}
`;
