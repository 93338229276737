/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Container,
  IconHelp,
  HelpText,
  ButtonContainer,
  LinkHelp,
} from "./styles";
import signout from "../../../Images/sign-out.svg";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

const HeaderMenu = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();

  const languageHelp = React.useCallback((key: string) => {
    switch (key) {
      case "pt-BR":
        return "pt-BR";
      case "en-US":
        return "en";
      case "es-AR":
        return "es";
      case "zh-CN":
        return "zh";
      default:
        return "en";
    }
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleLogOut = React.useCallback(() => {
    if (keycloak) {
      keycloak.logout();
    }
  }, [keycloak, handleClose]);

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Container>
      <Stack direction="row" spacing={2}>
        <div>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreVertIcon color="action" />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      style={{ minWidth: 120, paddingLeft: 8, paddingRight: 8 }}
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <LinkHelp
                        rel="noreferrer"
                        target="_blank"
                        href={`https://ajuda.agriness.com/${languageHelp(
                          i18n.language
                        )}/support/home`}
                      >
                        <IconHelp />
                        <HelpText>{t("help")}</HelpText>
                      </LinkHelp>
                      <ButtonContainer onClick={handleLogOut}>
                        <img src={signout} alt="sing out" />
                        <p>{t("exit")}</p>
                      </ButtonContainer>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    </Container>
  );
};

export default HeaderMenu;
