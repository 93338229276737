import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  background: #e9effb;
  border-radius: 4px;
  margin: 18px 0 8px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  svg{
      margin: 0 8px;
  }
  p {
    padding: 6px 0;
    margin: 0;
    text-align: left;
    font-size: 12px;
    font-family: "OpenSansRegular";
    font-weight: normal;
    color: ${(props) => props.theme.getColor("common", "grey-dark")};
  }
`;
