import Box from "@material-ui/core/Box";
import styled from "styled-components";

export const BoxContainer = styled(Box)`
  min-width: 40px;
  border-radius: 4px;
  .MuiSelect-select {
    padding: 4px 1px 4px 6px;
    font-size: 12px;
    font-family: "OpenSansRegular";
    color: ${(props) => props.theme.getColor("common", "grey-dark")};
  }
`;
