import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import media from "styled-media-query";

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    height: 400px;
  }
`;
export const DialogActionsStyled = styled(DialogActions)``;
export const DialogContentStyled = styled(DialogContent)`
  width: 600px;
  padding: 70px;
  text-align: center;

  ${media.lessThan("medium")`
    width: auto;
  `}
`;
export const DialogContentTextStyled = styled(DialogContentText)`
  margin-top: 30px;
  margin-bottom: 28px;
  font-weight: bold;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
`;
export const DialogTitleStyled = styled(DialogTitle)``;

export const Icon = styled.img`
  width: 65px;
  height: 65px;
`;
