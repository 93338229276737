import React, { useCallback } from "react";
import {
  ContainerHeader,
  LogoContainer,
  ImageLogo,
  IconUser,
  HeaderItem,
  UserItem,
  HelpItem,
  IconHelp,
  HelpText,
  LogoutButton,
  LogoutImage,
  MenuStyled,
  MenuItem,
  UserInfos,
  AvatarStyled,
  Link,
  IconUserLogout,
  ExitLabel,
  Triangle,
  TooltipArrow,
} from "./styles";
import logo from "../../Images/logo.png";
import NavigationItem from "./NavigationItem";
import { paths } from "./paths";
import { useKeycloak } from "@react-keycloak/web";
import { Popover } from "@material-ui/core";
import signout from "../../Images/signout.png";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import HeaderMenu from "./HeaderMenu";
import { useTranslation } from "react-i18next";

const Header = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const { keycloak } = useKeycloak();

  const handleClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLogOut = useCallback(() => {
    if (keycloak) {
      handleClose();
      keycloak.logout();
    }
  }, [keycloak, handleClose]);

  const languageHelp = useCallback((key: string) => {
    switch (key) {
      case "pt-BR":
        return "pt-BR";
      case "en-US":
        return "en";
      case "es-AR":
        return "es";
      case "zh-CN":
        return "zh";
      default:
        return "en";
    }
  }, []);

  const manipulateImageSize = () => {
    if (user?.photo) {
      const [url] = user?.photo?.split("&s");
      if (url) {
        return `${url}&s=200`;
      }
    }
  };

  const hasAvatar = () => {
    const [, urlNumber] = user?.photo?.split("/avatar/");
    if (urlNumber?.split("=")[0] === "566e5f41c7b365aa31bed5b819932ca6?d") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ContainerHeader>
      <LogoContainer xs={12} sm={2} lg={2} xl={2}>
        <ImageLogo src={logo} />
        <HeaderMenu />
      </LogoContainer>
      <HeaderItem xs={12} sm={8} lg={8} xl={8}>
        {paths.map((path, index) => {
          return (
            <NavigationItem key={index} path={path.path} name={t(path.name)} />
          );
        })}
      </HeaderItem>
      <HeaderItem style={{ textAlign: "end" }} sm={2} lg={2} xl={2}>
        <HelpItem sm={4} lg={4}>
          <TooltipArrow
            title={
              <>
                <b> {t("tooltipHelpTitle")} </b> <br />
                {t("tooltipHelpSubTitle")}
              </>
            }
            arrow
          >
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://ajuda.agriness.com/${languageHelp(
                i18n.language
              )}/support/home`}
            >
              <IconHelp />
              <HelpText>{t("help")}</HelpText>
            </a>
          </TooltipArrow>
        </HelpItem>

        <UserItem sm={8} lg={8}>
          {console.log("hasAvatar", hasAvatar())}
          {hasAvatar() ? (
            <img onClick={handleClick} src={user?.photo} alt="" />
          ) : (
            <IconUser
              style={{ cursor: "pointer" }}
              aria-controls="simple"
              aria-haspopup="true"
              onClick={handleClick}
            />
          )}
        </UserItem>
        <Popover
          style={{ top: 10 }}
          id="simple"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Triangle></Triangle>
          <MenuStyled>
            <MenuItem>
              {hasAvatar() ? (
                <div>
                  <img src={manipulateImageSize()} alt="" />
                </div>
              ) : (
                <AvatarStyled>
                  <IconUserLogout></IconUserLogout>
                </AvatarStyled>
              )}
              <UserInfos>
                <b>{user?.name || ""}</b> <br></br>
                {user?.email || ""} <br></br>
                <Link href="/">{t("myAccount")}</Link>
              </UserInfos>
            </MenuItem>
            <LogoutButton onClick={handleLogOut}>
              <LogoutImage alt="signout" src={signout} />
              <ExitLabel>{t("exit")}</ExitLabel>
            </LogoutButton>
          </MenuStyled>
        </Popover>
      </HeaderItem>
    </ContainerHeader>
  );
};
export default Header;
