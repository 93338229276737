import React, { useCallback } from "react";
import {
  FormHelperTextStyled,
  SelectStyled,
  FormControlStyled,
  InputLabelStyled,
  MenuItemStyled,
} from "./styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export type Option = {
  label: string;
  value: string | number;
};

export type Props = {
  label: string;
  helperText: string;
  options: Option[];
  value: string | number;
  onChange: Function;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  textId?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      // minWidth: 190,
      width: "100%",
      textAlign: "left",
    },
  })
);

export const CustomSelect = ({
  label,
  helperText,
  error,
  errorText,
  disabled,
  options,
  onChange,
  value,
  textId
}: Props) => {
  const classes = useStyles();

  const handleOnChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <FormControlStyled
      error={error}
      variant="outlined"
      className={classes.formControl}
      size="small"
    >
      <InputLabelStyled>{label}</InputLabelStyled>
      <SelectStyled
        onChange={handleOnChange}
        disabled={disabled}
        id={"select-outlined"}
        value={value}
        label={label}
        data-testid={textId}
      >
        {options?.map((opt, index) => {
          return (
            <MenuItemStyled key={index} value={opt.value}>
              {opt.label}
            </MenuItemStyled>
          );
        })}
      </SelectStyled>
      <FormHelperTextStyled>
        {error ? errorText : helperText}
      </FormHelperTextStyled>
    </FormControlStyled>
  );
};

export default CustomSelect;
