import React from "react";
import * as S from "./styles";
import SearchIcon from "@material-ui/icons/Search";
import InputField from "../InputField";

interface Props {
  getSearch: (value: string) => void;
  searchFields: string;
}

const SearchtComponent = ({ getSearch, searchFields }: Props) => {
  const [searchTherme, setSearch] = React.useState<string>("");

  const handleSearch = (value: string) => {
    setSearch(value);
    !!getSearch && getSearch(value);
  };

  return (
    <S.PanelItem>
      <S.SearchWrapper>
        <S.SearchField>
          <InputField
            setValue={(value: string) => handleSearch(value)}
            adornment={<SearchIcon />}
            value={searchTherme}
            label={searchFields}
          />
        </S.SearchField>
      </S.SearchWrapper>
    </S.PanelItem>
  );
};

export default SearchtComponent;
