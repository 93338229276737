import React from "react";
import { CardProduct } from "./styles";

interface Props {
  children: React.ReactNode;
  isModule?: boolean;
}

const CardFormatProducts = ({ children, isModule = false }: Props) => {
  return <CardProduct isModule={isModule}>{children}</CardProduct>;
};

export default CardFormatProducts;
