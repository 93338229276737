import styled from "styled-components";
import media from "styled-media-query";
import { Grid } from "@material-ui/core";

export const SearchWrapper = styled.div`
  display: flex;

  ${media.lessThan("medium")`
    margin: 1rem;
  `}
`;

export const SearchField = styled.div`
  width: 190px;
`;

export const PanelItem = styled(Grid).attrs({ item: true })`
  .table {
    overflow: auto;
  }

  ${media.lessThan("medium")`
    display: flex;
    flex-direction: column;

    .first-input {
      margin-bottom: 15px;
    }
  `}
`;