type Size = "small" | "medium" | "large";

type SizesPadding = {
  key: Size;
  value: string;
};

const sizesPaddingButton: SizesPadding[] = [
  {
    key: "small",
    value: "8px 8px",
  },
  {
    key: "medium",
    value: "7px 11px",
  },
  {
    key: "large",
    value: "8px 16px",
  },
];

const getPaddingByButtonSize = (size: Size) => {
  return (
    sizesPaddingButton.find(
      (sizePaddingButton) => sizePaddingButton.key === size
    )?.value ?? "8px 8px"
  );
};

export { getPaddingByButtonSize };
