import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Redirect, useLocation } from "react-router-dom";

const Login = () => {
  const location = useLocation<{ [key: string]: unknown }>();
  const currentLocationState = location.state || {
    from: { pathname: "/personal-data" },
  };
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak?.authenticated) {
      keycloak?.login();
    }
  }, [keycloak]);

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string} />;
  }

  return <div>loading</div>;
};

export default Login;
