import React, { useCallback } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { FormLabelStyled, FormControlLabelStyled } from "./styles";
export type RadioButton = {
  label: string;
  value: string;
};

export type Props = {
  label: string;
  radios: RadioButton[];
  onChange: Function;
  value: string;
  textId: string;
};

export default function FormControlLabelPlacement({
  label,
  radios,
  onChange,
  value,
  textId
}: Props) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <FormControl component="fieldset">
      <FormLabelStyled component="legend">{label}</FormLabelStyled>
      <RadioGroup
        value={value}
        onChange={handleChange}
        data-testid={textId}
        row
        aria-label="position"
        name="position"
        defaultValue="top"
      >
        {radios.map((radio, index) => {
          return (
            <FormControlLabelStyled
              key={index}
              value="end"
              control={<Radio color="primary" value={radio.value} />}
              label={radio.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
