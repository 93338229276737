import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AxiosError } from "axios";

import api from "../../../Service/api";

import { Collapse, Snackbar } from "@material-ui/core";

import Button from "../../../Components/Button";
import Error from "../../../Images/error.svg";
import PersonIcon from "@material-ui/icons/Person";
import CloseIcon from "@material-ui/icons/Close";
import { Divider } from "@material-ui/core";

import {
  AlertStyled,
  ButtonContainer,
  ButtonStyled,
  Close,
  CustomFormSelect,
  DialogContentStyled,
  DialogContentTextStyled,
  Header,
  PanelItem,
  SelectContainer,
  SubTitle,
  Title,
} from "./styles";
import InputField from "../../../Components/InputField";
import CustomSelect from "../../../Components/CustomSelect";
import MultipleSelect from "../../../Components/MulitpleSelect";
import InfoModal from "../../../Components/infoModal";

import LoadingFeedBack from "../../../Components/LoadingFeedBack";
import NegativeFeedback from "../../../Components/NegativeFeedback";

type LinkFarmProps = {
  emailValidation?: string;
  farm?: string;
  user_relation: string;
  products: string[];
  name_to: string;
  email_to: string;
  modules: string[];
};

type IParams = {
  id: string;
};

type IUsersFarms = {
  id: string;
  name: string;
  relation_type: string;
  modules: string[];
  products: string[];
  email: string;
  relation_id: string;
};

export interface IAddFormUserInFarmProps {
  isOpen: boolean;
  isClose: () => void;
  listProducts: string[];
  userFarms: IUsersFarms;
  getUpdate: (value: boolean) => void;
  showModalFeedBack: (value: boolean) => void;
  showModalFeedBackEdit: (value: boolean) => void;
  listModules: string[];
}

const AddFormUserInFarm = (AddFormUserprops: IAddFormUserInFarmProps) => {
  const {
    isOpen,
    isClose,
    getUpdate,
    showModalFeedBack,
    showModalFeedBackEdit,
    listProducts,
    userFarms,
    listModules,
  } = AddFormUserprops;

  const { t } = useTranslation();
  const params = useParams<IParams>();

  const [update, setUpdate] = useState(false);
  const [loadingFeedBack, setLoadingFeedbak] = useState(false);
  const [modalValidation, setModalValidation] = useState(false);
  const [negativeModalFeedback, setnegativeModalFeedback] = useState(false);

  const formik = useFormik<LinkFarmProps>({
    initialValues: {
      farm: "",
      name_to: "",
      email_to: "",
      emailValidation: "",
      products: [],
      modules: [],
      user_relation: "",
    },
    onSubmit: (values) => {},
  });
  const {
    values,
    setFieldValue,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = formik;

  useEffect(() => {
    if (userFarms !== undefined) {
      setValues({
        email_to: userFarms?.email === undefined ? "" : userFarms?.email,
        name_to: userFarms?.name === undefined ? "" : userFarms?.name,
        products: userFarms?.products === undefined ? [] : userFarms?.products,
        modules: userFarms?.modules === undefined ? [] : userFarms?.modules,
        user_relation:
          userFarms?.relation_type === undefined
            ? ""
            : userFarms?.relation_type,
      });
    }
  }, [setValues, userFarms]);

  const handleSelectName = (value: string) => {
    setFieldValue("name_to", value);
  };

  const handleSelectEmail = (value: string) => {
    setFieldValue("email_to", value);
  };

  const handleSelectType = (selectType: string) => {
    setFieldValue("user_relation", selectType);
  };

  const handleSelectProducts = (value: Array<any>) => {
    setFieldValue("products", value);
  };

  const handleSelectProductsEdit = (value: Array<any>) => {
    const ids = value.map((item) => item);
    setFieldValue("products", ids);
  };

  const handleSelectedModules = (value: Array<any>) => {
    setFieldValue("modules", value);
  };

  const handleSelectedModulesEdit = (value: Array<any>) => {
    const ids = value.map((item) => item);
    setFieldValue("modules", ids);
  };

  const closeModal = () => {
    isClose();
  };

  const handleClose = (isEdit:boolean) => {
    setModalValidation(false);
    resetForm();
    //usado para atualizar o effect fora da table
    setUpdate((props) => !props);
    !!getUpdate && getUpdate(update);
    if(isEdit) {
      !!showModalFeedBackEdit && showModalFeedBackEdit(true);
    }else{
      !!showModalFeedBack && showModalFeedBack(true);
    }
    closeModal();
  };

  //API adicionar usuario a granja
  const handleSubmit = async () => {
    const data = {
      farm: params.id,
      user_relation: values.user_relation,
      products: values.products,
      name_to: values.name_to,
      email_to: values.email_to,
      modules: values.modules,
    };
    setValues(data);

    const validationName = Yup.object().shape({
      name_to: Yup.string().required(t("required")),
    });

    const validName = await validationName
      .validate(data, { abortEarly: false })
      .catch((err) => err.errors);

    const validationEmail = Yup.object().shape({
      email_to: Yup.string().email(t("invalidEmail")).required(t("required")),
    });

    const validEmail = await validationEmail
      .validate(data, { abortEarly: false })
      .catch((err) => err.errors);

    const validationProducts = Yup.object().shape({
      products: Yup.array().min(1, t("required")).required(t("required")),
    });

    const validProducts = await validationProducts
      .validate(data, { abortEarly: false })
      .catch((err) => err.errors);

    const validationRelationTypes = Yup.object().shape({
      user_relation: Yup.string().required(t("required")),
    });

    const validRelationTypes = await validationRelationTypes
      .validate(data, { abortEarly: false })
      .catch((err) => err.errors);

    setErrors({
      name_to: validName.length === undefined ? "" : validName,
      email_to: validEmail.length === undefined ? "" : validEmail,
      products: validProducts.length === undefined ? "" : validProducts,
      user_relation:
        validRelationTypes.length === undefined ? "" : validRelationTypes,
    });

    if (
      validName.length !== undefined ||
      validEmail.length !== undefined ||
      validProducts.length !== undefined ||
      validRelationTypes.length !== undefined
    ) {
      return;
    }
    if (
      data.products.length == 0 ||
      data.email_to === "" ||
      data.user_relation === "" ||
      data.name_to === ""
    ) {
      return;
    }
    setLoadingFeedbak(true);
    try {
      await api().post(`/invites/`, data);
      setLoadingFeedbak(false);
      // !!showModalFeedBack && showModalFeedBack(true);
      handleClose(false);
      resetForm();
      !!getUpdate && getUpdate(update);
    } catch (error) {
      setLoadingFeedbak(false);
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        setErrors({
          emailValidation: t("alreadyEmail"),
        });
        setModalValidation(true);
      } else {
        setnegativeModalFeedback(true);
      }
    }
  };

  //API EDITAR usuario Da granja
  const handleSubmitEdit = async () => {
    const data = {
      relation_type: values.user_relation,
      products: values.products,
      modules: values.modules,
    };

    const validationProducts = Yup.object().shape({
      products: Yup.array().min(1, t("required")).required(t("required")),
    });

    const validProducts = await validationProducts
      .validate(data, { abortEarly: false })
      .catch((err) => err.errors);

    const validationRelationTypes = Yup.object().shape({
      relation_type: Yup.string().required(t("required")),
    });

    const validRelationTypes = await validationRelationTypes
      .validate(data, { abortEarly: false })
      .catch((err) => err.errors);

    setErrors({
      products: validProducts.length === undefined ? "" : validProducts,
      user_relation:
        validRelationTypes.length === undefined ? "" : validRelationTypes,
    });

    if (
      validProducts.length !== undefined ||
      validRelationTypes.length !== undefined
    ) {
      return;
    }
    if (data.products.length == 0 || data.relation_type === "") {
      return;
    }

    setLoadingFeedbak(true);
    try {
      await api().put(
        `/v2/farms/${params.id}/farmuser/${userFarms?.relation_id}/`,
        data
      );
      !!getUpdate && getUpdate(update);
      setLoadingFeedbak(false);
      // !!showModalFeedBackEdit && showModalFeedBackEdit(true);
      handleClose(true);
    } catch (error) {
      setLoadingFeedbak(false);
      setnegativeModalFeedback(true);
    }
  };

  //validacao do input do modal ADD
  const validationModal = () => {
    const TransitionLeft = (props: any) => {
      return <Collapse {...props} orientation="horizontal" />;
    };
    return (
      <>
        <Snackbar
          open={modalValidation}
          autoHideDuration={5000}
          transitionDuration={{ enter: 1000, exit: 5000 }}
          TransitionComponent={TransitionLeft}
          TransitionProps={{ enter: true, exit: false }}
          onClose={() => {
            setModalValidation(false);
          }}
          style={{ position: "initial" }}
        >
          <AlertStyled
            icon={false}
            severity="error"
            onClose={() => {
              setModalValidation(false);
            }}
          >
            <img alt="error" src={Error} />
            {t("alreadyLink")}
          </AlertStyled>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      <DialogContentStyled>
        <DialogContentTextStyled id="alert-dialog-description">
          <Header>
            <PersonIcon />
            {isOpen ? (
              <SubTitle>{t("modalUserFarmsTitle")}</SubTitle>
            ) : (
              <SubTitle>{t("modalEditUserFarmsTitle")}</SubTitle>
            )}
            <Close onClick={closeModal}>
              <CloseIcon />
            </Close>
          </Header>
          <Divider></Divider>
          <PanelItem lg={12} md={12} sm={12} style={{ margin: "0.5rem 0 0 0" }}>
            <Title style={{ marginBottom: "14px" }}>
              {t("modalUserFarmsLabelInfo")}
            </Title>
          </PanelItem>

          <PanelItem lg={12} md={12} sm={12} style={{ display: "flex" }}>
            <div
              className="first-input"
              style={{ width: "100%", marginRight: "8px" }}
            >
              <InputField
                key="name"
                error={!!errors.name_to}
                setValue={(value: string) => handleSelectName(value)}
                disabled={isOpen ? false : true}
                value={isOpen ? values.name_to : userFarms?.name!}
                label={t("fullName")}
                errorMessage={errors.name_to}
              />
            </div>
            <InputField
              key="email"
              error={
                modalValidation ? !!errors.emailValidation : !!errors.email_to
              }
              setValue={(value: string) => handleSelectEmail(value)}
              disabled={isOpen ? false : true}
              value={isOpen ? values.email_to : userFarms?.email!}
              label={t("modalUserFarmsInputEmail")}
              errorMessage={
                modalValidation ? errors.emailValidation : errors.email_to
              }
            />
          </PanelItem>
          {isOpen && <InfoModal isShow={isOpen} />}
          <SelectContainer style={{ marginTop: !isOpen ? "24px" : 0 }}>
            <CustomFormSelect
              className="firs-select"
              style={{ width: "100%", marginRight: "8px" }}
            >
              <Title style={{ margin: "0 0 13px 0" }}>
                {t("modalUserFarmsLabelType")}
              </Title>
              <CustomSelect
                errorText={errors.user_relation}
                error={!!errors.user_relation}
                helperText=""
                onChange={handleSelectType}
                value={values.user_relation}
                label={t("modalUserFarmsSelectLink")}
                options={[
                  { label: t("PARTNER"), value: "PARTNER" },
                  { label: t("EMPLOYEE"), value: "EMPLOYEE" },
                  { label: t("CONSULTING"), value: "CONSULTING" },
                ]}
              />
            </CustomFormSelect>
            <CustomFormSelect style={{ width: "100%" }}>
              <Title style={{ margin: "0 0 13px 0" }}>{t("Products")}</Title>
              {isOpen ? (
                <MultipleSelect
                  value={listProducts}
                  getValue={handleSelectProducts}
                  label={t("modalUserFarmsSelectProduct")}
                  error={!!errors.products}
                  textError={errors.products}
                  multipleLabel="selectProduct"
                />
              ) : (
                <MultipleSelect
                  value={listProducts}
                  getValue={handleSelectProductsEdit}
                  defaultValue={userFarms?.products}
                  label={t("modalUserFarmsSelectProduct")}
                  error={!!errors.products}
                  textError={errors.products}
                  multipleLabel="selectProduct"
                />
              )}
            </CustomFormSelect>
          </SelectContainer>
        
          {listModules.length !== 0 && (
            <SelectContainer style={{ marginTop: "18px" }}>
              <CustomFormSelect style={{ width: "49%" }}>
                <Title style={{ margin: "0 0 13px 0" }}>{t("Module")}</Title>
                {isOpen ? (
                  <MultipleSelect
                    isModule
                    value={listModules}
                    getValue={handleSelectedModules}
                    multipleLabel="Module"
                    label={t("moduleSelected")}
                    
                  />
                ) : (
                  <MultipleSelect
                    isModule
                    value={listModules}
                    getValue={handleSelectedModulesEdit}
                    defaultValue={userFarms?.modules}
                    multipleLabel="Module"
                    label={t("moduleSelected")}
                  />
                )}
              </CustomFormSelect>
            </SelectContainer>
          )}
        </DialogContentTextStyled>
      </DialogContentStyled>
      <ButtonStyled>
        {modalValidation ? (
          validationModal()
        ) : (
          <ButtonContainer>
            <Button
              variant="primary"
              size="medium"
              label={t("save")}
              onClick={isOpen ? handleSubmit : handleSubmitEdit}
            />
          </ButtonContainer>
        )}
      </ButtonStyled>
      <NegativeFeedback
        success={negativeModalFeedback}
        error={false}
        setStateModal={(value) => setnegativeModalFeedback(!value)}
      />
      <LoadingFeedBack
        success={loadingFeedBack}
        error={false}
        setStateModal={(value) => setLoadingFeedbak(!value)}
      />
    </>
  );
};

export default AddFormUserInFarm;
