import { all, debounce } from "redux-saga/effects";
import { SUCCESS, FAILURE } from "../../Actions/Feedback";
import { put, select } from "redux-saga/effects";

function* dismissSuccess(): any {
  const store = yield select();
  if (store.feedback.showSuccess) {
    yield put({ type: SUCCESS });
  }
}

function* dismissFailure(): any {
  const store = yield select();
  if (store.feedback.showFailure) {
    yield put({ type: FAILURE });
  }
}

export function* watchFeedbackScreen(): any {
  yield all([
    yield debounce(3000, SUCCESS, dismissSuccess),
    yield debounce(3000, FAILURE, dismissFailure),
  ]);
}
