import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as S from "./styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface ValuesProps {
  value: string | number;
  label: string | number;
}
interface SelectBoxProps {
  values: ValuesProps[];
  initalValue: number | string;
  getValue: (value: number | string) => void;
}

const SelectBox = ({ values, initalValue, getValue }: SelectBoxProps) => {
  const [value, setValue] = React.useState(initalValue);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    if (getValue) {
      getValue(event.target.value);
    }
  };

  React.useEffect(() => {
    setValue(initalValue);
  }, [initalValue]);

  return (
    <S.BoxContainer>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={String(value)}
          onChange={handleChange}
        >
          {values.map((item: ValuesProps) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </S.BoxContainer>
  );
};

export default SelectBox;
