import styled from "styled-components";
import { Grid } from "@material-ui/core";
import media from "styled-media-query";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export const Container = styled.div`
  background: #fff;
  padding-left: 16px;
  padding-right: 24px;
`;

export const ContainerCard = styled.div`
  display: flex;
`;

export const IconDelete = styled(DeleteIcon)`
  width: 16px;
  height: 16px;
  color: #d1d7db;
  cursor: pointer;
`;

export const ModulesContainer = styled.div`
  margin-left: 28px;
  > h3 {
    font-family: "OpenSansBold";
    font-size: 10px;
    color: #000000;
  }
`;

export const ModuleContent = styled.div`
  display: flex;
`;

export const PanelFarm = styled(Grid).attrs({
  container: true,
})`
  /* padding: ${(props) => props.theme.getPanelSize(props.lang).padding}; */
  padding-top: 10px;
  border-radius: 4px;
  /* min-height: 500px; */
  height: auto;
  display: inline-block;
  box-shadow: ${(props) => props.theme.shadows[6]};
  border: solid 1px ${(props) => props.theme.getColor("backgrounds", "light")};
  background-color: ${(props) => props.theme.getColor("common", "white")};

  ${media.lessThan("medium")`
    margin: 1.5rem 0 0 0rem;
  `}
`;

export const PanelContainer = styled(Grid).attrs({})`
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  /* width: 100%; */
  margin-bottom: 34px;
  ${media.lessThan("medium")`
  margin-bottom: 0;
    flex-direction: column;
  `}
`;
export const PanelItem = styled(Grid).attrs({ item: true })``;

export const IconEdit = styled(EditIcon)`
  width: 16px;
  height: 16px;
  color: #d1d7db;
  cursor: pointer;
`;

export const Divider = styled.hr`
  border: 1px solid ${(props) => props.theme.getColor("backgrounds", "light")};
  color: ${(props) => props.theme.getColor("backgrounds", "light")};
  margin: 30px 0 20px 0;
`;
export const Title = styled.div`
  font-family: "OpenSansBold";
  font-size: 12px;
  margin-bottom: 10px;
  ${media.lessThan("medium")`
      margin-left: 24px;
    `}
`;
export const InformationsGrid = styled(Grid).attrs({ item: true })`
  padding: 0 5px 15px 0;
  .fCJHUJ .MuiOutlinedInput-input {
    height: 1px;
  }
  .fCJHUJ .MuiOutlinedInput-adornedEnd {
    background: #d1d7db33;
  }
`;
export const SubTitle = styled.h3`
  width: 100%;
  font-weight: bold;
  font-family: OpenSansBold;
  font-size: 18px;
`;
