import {
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import styled from "styled-components";
import media from "styled-media-query";

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    width: 656px;
    height: 450px;
  }

  ${media.lessThan("medium")`
  .MuiDialog-paper {
    margin: 0 !important;
  }
  .MuiDialog-paperScrollPaper{
    max-height: 100% !important;
  }
  .MuiDialog-paperScrollPaper {
    width: 100%;
    height: 100%;
  }
  `}
`;

export const DialogContentStyled = styled(DialogContent)`
  text-align: right;
`;

export const DialogContentTextStyled = styled(DialogContentText)`
  font-weight: bold;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
`;

export const ButtonStyled = styled(Grid).attrs({ container: true })`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f2f2f2;
  height: 60px;
  ${media.lessThan("medium")`
    justify-content: center;
    background: none;
  `}
`;

export const ButtonContainer = styled.div`
  margin-right: 16px;

  ${media.lessThan("medium")`
    width: 100%;
    margin: 0 32px;
    height: 100%;
    > button {
      width: 100%;
      padding: 22px;
    }
  `}
`;
