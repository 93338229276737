import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import Visibility from "@material-ui/icons/Visibility";
import media from "styled-media-query";
export const Title = styled.h1`
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  margin-left: 24px;
  font-family: OpenSansBold;
  text-transform: uppercase;
  margin-bottom: 14px;
`;

export const UserInfoContent = styled.aside`
  border-radius: 4px;
  min-height: 440px;
  max-width: 300px;
  padding: 0 33px;
  box-shadow: ${(props) => props.theme.shadows[6]};
  border: solid 1px ${(props) => props.theme.getColor("backgrounds", "light")};
  background-color: ${(props) => props.theme.getColor("common", "white")};
`;

export const CardContainer = styled.div``;

export const PanelContainer = styled(Grid).attrs({ container: true })`
  padding: 0 1.5rem 0 1.5rem;
`;

export const UserInfoHeader = styled(Grid).attrs({ item: true })`
  div {
    margin-top: 54px;
    img {
      border-radius: 50%;
      width: auto;
    }
  }
`;

export const Content = styled(Grid).attrs({ item: true })``;

export const PanelItem = styled(Grid).attrs({ item: true })`
  margin-bottom: 16px;
`;

export const ButtonContent = styled(Grid).attrs({ item: true })`
  /* margin-top: 16px; */
`;

export const Divider = styled.hr`
  border: 1px solid ${(props) => props.theme.getColor("backgrounds", "light")};
  color: ${(props) => props.theme.getColor("backgrounds", "light")};
  padding: 0 1rem 0 1rem;
  margin-top: 130px;

  ${media.lessThan("medium")`
    margin-top: 0px;
  `}
`;

export const IconInfo = styled(Info)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.getColor("primary", "primary")};
  }
`;

export const IconEye = styled(Visibility)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.getColor("common", "grey-medium")};
  }
`;

export const SubTitle = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  text-align: center;
  font-family: OpenSansBold;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  font-size: 12px;
  text-align: center;
`;

export const ParagraphInformation = styled(Paragraph)`
  color: ${(props) => props.theme.getColor("primary", "primary")};
  text-transform: uppercase;
  font-size: 10px;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.getColor("primary", "primary")};
  font-family: OpenSansBold;
`;

export const PageTitle = styled.div`
  width: 100%;
  margin: 1.5rem 0 0.5rem 7.3rem;
  font-family: OpenSansBold;
  font-size: 18px;
`;
