import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import Visibility from "@material-ui/icons/Visibility";
import media from "styled-media-query";
import EditIcon from "@material-ui/icons/Edit";

export const Title = styled.h1`
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  margin-left: 24px;
  font-family: OpenSansBold;
  text-transform: uppercase;
`;

export const IconEdit = styled(EditIcon)`
  width: 16px;
  height: 16px;
  color: #d1d7db;
  cursor: pointer;
`;

export const PanelFarm = styled(Grid).attrs({
  container: true,
})`
  /* padding: ${(props) => props.theme.getPanelSize(props.lang).padding}; */
  padding: 0px 1.5rem;
  border-radius: 4px;
  height: auto;
  display: inline-block;
  box-shadow: ${(props) => props.theme.shadows[6]};
  border: solid 1px ${(props) => props.theme.getColor("backgrounds", "light")};
  background-color: ${(props) => props.theme.getColor("common", "white")};
  margin-bottom: 20px;

  ${media.lessThan("medium")`
    height: 550px;
    overflow: auto;
  `}
`;

export const PanelContainer = styled(Grid).attrs({ container: true })`
  padding: 0 1.5rem 0 1.5rem;
  width: 100%;
`;

export const PanelItem = styled(Grid).attrs({ item: true })``;

export const IconInfo = styled(Info)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.getColor("primary", "primary")};
  }
`;

export const IconEye = styled(Visibility)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.getColor("common", "grey-medium")};
  }
`;

export const SubTitle = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  text-align: center;
  font-family: OpenSansBold;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  font-size: 12px;
  text-align: center;
`;

export const ParagraphInformation = styled(Paragraph)`
  color: ${(props) => props.theme.getColor("primary", "primary")};
  text-transform: uppercase;
`;

export const PageTitle = styled.div`
  width: 100%;
  margin: 1.5rem 0 0.5rem 7.3rem;
  font-family: OpenSansBold;
  font-size: 18px;
`;

export const Divider = styled.hr`
  border: 1px solid ${(props) => props.theme.getColor("backgrounds", "light")};
  color: ${(props) => props.theme.getColor("backgrounds", "light")};
  padding: 0 1.5rem 0 1.5rem;
`;
