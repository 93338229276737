import React, { useState } from "react";
import InputField from "../../Components/InputField";
import { formatNameLocation } from "../../Utils";
import {
  PanelContainer,
  InformationsGrid,
  PanelFarm,
  Divider,
  Title,
  PanelItem,
  IconEdit,
  IconDelete,
  ContainerCard,
  ModulesContainer,
  ModuleContent,
} from "./styles";
import AddIcon from "@material-ui/icons/Add";
import { Fab } from "@material-ui/core";
import CardFormatProducts from "../../Components/CardProduct";
import {
  translateNamesProducts,
  translateNameModules,
} from "../../Utils/formats";
import Table, { TableCell, TableRow } from "../../Components/Table";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import api from "../../Service/api";
import DialogModal from "../../Components/DialogModal";
import AddFormUserInFarm from "./AddFormUserInFarm";
import Feedback from "../../Components/Feedback";

interface IParams {
  id: string;
}

interface InputSetProps {
  setValue: () => void;
  value: any;
  label: string;
  key: string;
}

interface UserFarmsPops {
  id: string;
  name: string;
  relation_type: string;
  products: string[];
  email: string;
  relation_id: string;
  modules: string[];
}

interface RenderProps {
  first: InputSetProps[];
  second: InputSetProps[];
}

interface RenderItens {
  name: string;
  email: string;
  relation_type: string;
  products: string[];
  start: string;
  modules: Array<string>;
}

const ListUsers = () => {
  const { t } = useTranslation();
  const params = useParams<IParams>();
  const [userFarms, setUserFarms] = useState<UserFarmsPops[]>([]);
  const [modules, setModules] = useState<Array<string>>([]);
  const [user, setUser] = useState<UserFarmsPops>();
  const [listProducts, setListProducts] = React.useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [count, setCountFarms] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [inputs, setInputs] = useState<RenderProps>({
    first: [],
    second: [],
  } as RenderProps);
  const [search, setSearch] = useState<string>();
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);

  const [update, setUpdate] = useState<boolean>(false);
  const [modalFeedbackDel, setModalFeedbackDel] = useState(false);

  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalFeedbackEdit, setModalFeedbackEdit] = useState(false);

  React.useEffect(() => {
    api()
      .get(`/v2/farms/${params.id}/users/`, {
        params: {
          search: search,
          page_size: pageSize,
          page: page,
        },
      })
      .then((response) => {
        setUserFarms(response.data.results);
        setCountFarms(response.data.count);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [pageSize, params.id, search, page, update]);

  React.useEffect(() => {
    api()
      .get(`/v2/farms/${params.id}/`)
      .then((response) => {
        setModules(response.data.modules);
        api()
          .get(
            `/v2/farms/${params.id}/users/?user__id=${response.data.owner.id}`
          )
          .then((res) => {
            setListProducts(res.data.results[0].products);
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          });

        const farm = response.data;
        if (farm !== undefined) {
          setInputs({
            first: [
              {
                setValue: () => null,
                value: farm ? farm.name : "",
                label: t("FarmName"),
                key: "farmName",
              },
              {
                setValue: () => null,
                value: farm ? farm.type : "",
                label: t("Species"),
                key: "species",
              },
              {
                setValue: () => null,
                value: farm ? farm.production_type : "",
                label: t("TypeOfProduction"),
                key: "typeOfProduction",
              },
            ],
            second: [
              {
                setValue: () => null,
                value:
                  farm.city.length !== 0
                    ? formatNameLocation(
                        farm?.city.name,
                        farm?.city.state.name,
                        farm?.city.state.country.name
                      )
                    : "",
                label: t("Location"),
                key: "location",
              },
              {
                setValue: () => null,
                value: farm ? farm.currency_unit : "",
                label: t("Currency"),
                key: "currency",
              },
              {
                setValue: () => null,
                value: farm ? farm.weight_measurement_unit : "",
                label: t("Kilogram"),
                key: "weight",
              },
            ],
          });
        }
      });
  }, [params.id, t]);

  const handleUpdate = (value: boolean) => {
    setUpdate(value);
  };

  React.useEffect(() => {
    setPageSize(5);
    setPage(1);
  }, [search]);

  const handleChange = (value: string) => {
    if (value.length >= 3) {
      setSearch(value);
    } else if (value.length === 0) {
      setSearch("");
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    setIsOpenEdit(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsOpenEdit(false);
  };

  const handleEdit = (user: any) => {
    setUser(user);
    setIsOpenEdit(true);
  };

  const handleDelete = async (d: any) => {
    try {
      await api().delete(`/v2/farms/${params.id}/farmuser/${d.relation_id}/`);
      setModalFeedbackDel(true);
      !!handleUpdate && handleUpdate(!update);
      setUpdate((props) => !props);
      setModalFeedbackDel(false);
    } catch (error) {}
  };

  return (
    <>
      <PanelContainer>
        <PanelItem id="painel-large" xs={12} lg={12} md={12}>
          <PanelFarm>
            <PanelContainer>
              <Title>{t("FarmData")}</Title>
            </PanelContainer>
            <PanelContainer>
              {inputs.first.map((item) => (
                <InformationsGrid sm={4}>
                  <InputField
                    disabled
                    setValue={item.setValue}
                    value={item.value}
                    label={item.label}
                    key={item.key}
                  />
                </InformationsGrid>
              ))}
            </PanelContainer>
            <PanelContainer>
              {inputs.second.map((item) => (
                <InformationsGrid sm={4}>
                  <InputField
                    disabled
                    setValue={item.setValue}
                    value={item.value}
                    label={item.label}
                    key={item.key}
                  />
                </InformationsGrid>
              ))}
            </PanelContainer>
            {modules.length !== 0 && (
              <ModulesContainer>
                <h3>{t("modulesTitleFarm")}</h3>
                <ModuleContent>
                  {modules.map((item) => (
                    <CardFormatProducts isModule>
                      {translateNameModules(item).label}
                    </CardFormatProducts>
                  ))}
                </ModuleContent>
              </ModulesContainer>
            )}
            <PanelItem xs={12} sm={12} lg={12}>
              <Divider></Divider>
            </PanelItem>
            <PanelContainer>
              <Table
                header={
                  <>
                    <TableCell tableHeader orderTable>
                      {t("Action")}
                    </TableCell>
                    <TableCell tableHeader orderTable>
                      {t("Name")}
                    </TableCell>
                    <TableCell tableHeader orderTable>
                      {t("E-mail")}
                    </TableCell>
                    <TableCell tableHeader orderTable>
                      {t("Profile")}
                    </TableCell>
                    <TableCell tableHeader orderTable>
                      {t("Products")}
                    </TableCell>
                    <TableCell tableHeader>{t("Module")}</TableCell>
                  </>
                }
                renderItems={(item: RenderItens, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item.relation_type !== "OWNER" && (
                        <>
                          <IconEdit
                            onClick={() => {
                              handleEdit(item);
                            }}
                          />
                          <IconDelete onClick={() => handleDelete(item)} />
                        </>
                      )}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell ellipsis maxLength={20}>
                      {item.email}
                    </TableCell>
                    <TableCell>{t(`${item.relation_type}TABLE`)}</TableCell>
                    <TableCell>
                      <ContainerCard>
                        {item.products.map((item: string, index: number) => (
                          <CardFormatProducts key={index}>
                            {translateNamesProducts(item)}
                          </CardFormatProducts>
                        ))}
                      </ContainerCard>
                    </TableCell>
                    <TableCell>
                      <ContainerCard>
                        {item.modules.map((item: string, index: number) => (
                          <CardFormatProducts isModule key={index}>
                            {translateNameModules(item).label}
                          </CardFormatProducts>
                        ))}
                      </ContainerCard>
                    </TableCell>
                  </TableRow>
                )}
                data={userFarms}
                loading={isLoading}
                titleTable={t("UsersAssociatedWithTheFarm")}
                handleChange={handleChange}
                searchFields={t("SearchUser")}
                count={count}
                emptyLabel={t('DoesNotHaveRegisteredDataUserYet')}
                titleInBold
                pageSize={pageSize}
                icon={
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    style={{ marginLeft: 12, width: "36px", height: "36px" }}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <AddIcon />
                  </Fab>
                }
                getPageSize={(value: number) => setPageSize(value)}
                getPage={(value: number) => setPage(value)}
              />
            </PanelContainer>
          </PanelFarm>
        </PanelItem>
      </PanelContainer>
      {isOpen ? (
        <DialogModal
          isOpen={isOpen}
          isOpenEdit={isOpenEdit}
          children={
            <AddFormUserInFarm
              listModules={modules}
              isOpen={isOpen}
              isClose={handleClose}
              listProducts={listProducts}
              getUpdate={() => {
                setUpdate(update);
              }}
              userFarms={{
                id: "",
                name: "",
                relation_type: "",
                products: [],
                email: "",
                modules: [],
                relation_id: "",
              }}
              showModalFeedBack={setModalFeedback}
              showModalFeedBackEdit={setModalFeedbackEdit}
            />
          }
        ></DialogModal>
      ) : (
        <DialogModal
          isOpen={isOpen}
          isOpenEdit={isOpenEdit}
          children={
            <AddFormUserInFarm
              isOpen={isOpen}
              listModules={modules}
              isClose={handleClose}
              listProducts={listProducts}
              getUpdate={() => setUpdate(update)}
              userFarms={{
                id: user?.id!,
                name: user?.name!,
                relation_type: user?.relation_type!,
                products: user?.products!,
                email: user?.email!,
                relation_id: user?.relation_id!,
                modules: user?.modules!,
              }}
              showModalFeedBack={setModalFeedback}
              showModalFeedBackEdit={setModalFeedbackEdit}
            />
          }
        ></DialogModal>
      )}
      <Feedback
        success={modalFeedbackDel}
        error={false}
        labelSuccess={t("modalTextDelete")}
        labelError={t("modalError")}
      />
      <Feedback
        success={modalFeedback}
        error={false}
        labelSuccess={t("modalTextSuccessAndEdit")}
        labelSuccessOption={t("modalTextSuccessPlus")}
        labelError={t("modalError")}
      />
      <Feedback
        success={modalFeedbackEdit}
        error={false}
        labelSuccess={t("modalTextSuccessAndEdit")}
        labelError={t("modalError")}
      />
    </>
  );
};

export default ListUsers;
