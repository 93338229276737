import styled from "styled-components";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import media from "styled-media-query";

export const Container = styled.div`
  ${media.greaterThan("medium")`
    display: none;
  `}
  position: absolute;
  right: 0;
  z-index: 1;
`;
export const IconHelp = styled(HelpOutlineIcon)`
  color: ${(props) => props.theme.getColor("feedback", "alert")};
`;

export const HelpText = styled.span`
  color: ${(props) => props.theme.getColor("feedback", "alert")};
  font-size: 14px;
  margin-left: 6px;
  vertical-align: super;
  font-family: OpenSansBold;
`;

export const LinkHelp = styled.a`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #71767A;
`;

export const ButtonContainer = styled.a`
  border: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > p {
    font-size: 14px;
    margin-left: 6px;
    vertical-align: super;
    font-family: OpenSansBold;
    color: "#71767A";
  }
`;
