import styled from "styled-components";
import { Link } from "react-router-dom";
import media from "styled-media-query";

export const RouterLink = styled(Link)`
  height: 25px;
  position: relative;
  max-height: 44px;
  flex-grow: 0;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  ${media.lessThan("medium")`
   padding: 14px 20px 10px;
   font-size: 12px;
  `}
  ${media.lessThan("small")`
    font-size: 10px;
  `}
  padding: 14px 24px 10px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => props.theme.getColor("primary", "secondary")};
  font-family: OpenSansBold;
`;

export const RouterLinkNotSelected = styled(RouterLink)`
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
`;

export const Selected = styled.div`
  height: 4px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    to right,
    ${(props) => props.theme.getColor(props.color ?? "primary", "secondary")} 0%,
    ${(props) => props.theme.getColor(props.color ?? "primary", "tertiary")}
      100%
  );
`;
