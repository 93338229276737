import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import api from "../../Service/api";
import {
  PanelItem,
  PanelContainer,
  PageTitle,
  PanelFarm,
  IconEdit,
} from "./styles";
import Table, { TableCell, TableRow } from "../../Components/Table";

interface RenderItens {
  id: string;
  name: string;
  producer: {
    name: string;
  };
  type: string;
  relation_type: string;
  production_type: string[];
}

const Farm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [listFarms, setListFarms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [count, setCountFarms] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);

  const handleUser = (id: string) => {
    history.push(`/farms/${id}/users`);
  };

  React.useEffect(() => {
    setPageSize(5);
    setPage(1);
  }, [search]);

  const handleChange = (value: string) => {
    if (value.length >= 3) {
      setSearch(value);
    } else if (value.length === 0) {
      setSearch("");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    api()
      .get("/v1/users/farms/", {
        params: {
          search: search,
          page_size: pageSize,
          page: page,
        },
      })
      .then((response) => {
        setListFarms(response.data.results);
        setCountFarms(response.data.count);
        setIsLoading(false);
      });
  }, [page, pageSize, search]);

  return (
    <>
      <PanelContainer>
        <PageTitle id="titleMobile">
          {t("FarmsAssociatedWithTheAccount")}
        </PageTitle>
        <PanelItem id="painel-large" xs={12}>
          <PanelFarm>
            <Table
              data={listFarms}
              header={
                <>
                  <TableCell tableHeader orderTable>
                    {t("Action")}
                  </TableCell>
                  <TableCell tableHeader orderTable>
                    {t("Farms")}
                  </TableCell>
                  <TableCell tableHeader orderTable>
                    {t("Property")}
                  </TableCell>
                  <TableCell tableHeader orderTable>
                    {t("Species")}
                  </TableCell>
                  <TableCell tableHeader orderTable>
                    {t("TypeOfProduction")}
                  </TableCell>
                </>
              }
              renderItems={(item: RenderItens, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.relation_type === "OWNER" && (
                      <IconEdit onClick={() => handleUser(item.id)} />
                    )}
                  </TableCell>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>{item?.producer?.name}</TableCell>
                  <TableCell>{item?.type}</TableCell>
                  <TableCell>{item?.production_type}</TableCell>
                </TableRow>
              )}
              emptyLabel={t('DoesNotHaveRegisteredDataFarmYet')}
              loading={isLoading}
              titleTable={t("FarmListing")}
              handleChange={handleChange}
              searchFields={t("SearchFarms")}
              count={count}
              pageSize={pageSize}
              getPageSize={(value: number) => {
                setPage(1);
                setPageSize(value);
              }}
              getPage={(value: number) => setPage(value)}
            />
          </PanelFarm>
        </PanelItem>
      </PanelContainer>
    </>
  );
};

export default Farm;
