import { createStore, applyMiddleware } from "redux";
import { Reducers } from "../Reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import Saga from "../Saga";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  Reducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export type RootState = ReturnType<typeof Reducers>;

sagaMiddleware.run(Saga);
