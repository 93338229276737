const standardAction = "SAMPLE::";

export const COUNT = `${standardAction}COUNT`;
export const SAGA_COUNT = `${standardAction}SAGA_COUNT`;
export const SAGA_COUNT_DEBOUNCE = `${standardAction}SAGA_COUNT_DEBOUNCE`;

export const count = () => ({
  type: COUNT,
});

export const sagaCount = () => ({
  type: SAGA_COUNT,
});

export const sagaCountDebounce = () => ({
  type: SAGA_COUNT_DEBOUNCE,
});
