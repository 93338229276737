import styled, { keyframes } from "styled-components";

export const spin = keyframes`
   100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    animation: ${spin} 2s linear infinite;
  }
  p{
    font-size: 14px;
    font-weight: 600;
    font-family: "OpenSansBold";
    line-height: 1.71;
  }
`;
