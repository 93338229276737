import { User, PERSISTUSER } from "../../Actions/User";

export type reducerUser = {
  user: User;
};

const initialState: reducerUser = {
  user: {
    id: "",
    token: "",
    cell_phone: "",
    date_format: "",
    decimal_separator: "",
    email: "",
    language: "",
    loginEmail: "",
    name: "",
    phone: "",
    secondary_email: "",
    gender: "",
    whatsapp: "",
    photo: ""
  },
};

type Action = {
  type: any;
  payload: Partial<User>;
};

export const userReducer = (
  state = initialState,
  action: Action = { type: "", payload: {} }
) => {
  const { type, payload } = action;
  switch (type) {
    case PERSISTUSER:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    default:
      return state;
  }
};
