const standardAction = "USER::";

export type User = {
  id: string;
  token: string;
  name: string;
  email: string;
  secondary_email: string;
  phone: string;
  cell_phone: string;
  loginEmail: string;
  language: string;
  date_format: string;
  decimal_separator: string;
  gender: string;
  whatsapp: string;
  photo: string;
};

export const PERSISTUSER = `${standardAction}PERSISTUSER`;
export const SAVE_USER = `${standardAction}SAVE_USER`;

export const PersistUser = (user: Partial<User>) => ({
  type: PERSISTUSER,
  payload: user,
});

export const SaveUser = () => ({
  type: SAVE_USER,
});
