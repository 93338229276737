import { COUNT } from "../../Actions/Sample";

const initialState = {
  count: 0,
};

type Action = {
  type: any;
  payload: any;
};

export const sampleReducer = (
  state = initialState,
  action: Action = { type: "", payload: "" }
) => {
  const { type, payload } = action;
  switch (type) {
    case COUNT:
      return {
        ...state,
        count: state.count + payload,
      };
    default:
      return state;
  }
};
