import React from "react";
import {
  Paragraph,
  ParagraphInformation,
  CardContainer,
  PanelItem,
  SubTitle,
  Link,
  UserInfoHeader,
  Content,
} from "../styles";
import ProfileM from "../../../Images/perfil-homem.svg";
import ProfileF from "../../../Images/perfil-mulher.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store";
import { useTranslation } from "react-i18next";

export type Props = {
  userName?: string;
  gender?: string;
};

const UserInfo = ({ userName, gender }: Props) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.user);

  const manipulateImageSize = () => {
    if (user?.photo) {
      const [url] = user?.photo?.split("&s");
      const [, urlNumber] = user?.photo?.split("/avatar/");
      if (urlNumber.split("=")[0] === "566e5f41c7b365aa31bed5b819932ca6?d") {
        return gender === "MALE" ? ProfileM : ProfileF;
      }
      if (url) {
        return `${url}&s=200`;
      }
    }
  };

  return (
    <>
      <CardContainer>
        <UserInfoHeader xs={12} sm={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt="gender"
              width="94px"
              height="104px"
              src={manipulateImageSize()}
            />
          </div>
        </UserInfoHeader>
        <Content xs={12} sm={12} md={12}>
          <SubTitle>{userName}</SubTitle>
        </Content>
        <Content xs={12} sm={12} md={12}>
          <Paragraph>
            {t("welcomeAgriness")}
            <br></br>
            {t("welcomeAgrinessMessageInit")}{" "}
            <b> {t("welcomeAgrinessMessage")} </b>
          </Paragraph>
        </Content>
        <PanelItem xs={12} sm={12} md={12}>
          <Paragraph>
            <b>{t("profileDataMessage")}</b>
          </Paragraph>
        </PanelItem>
        <PanelItem xs={12}>
          <ParagraphInformation>
            <Link
              target="_blank"
              href="https://www.agriness.com/pt/politica-de-privacidade/"
            >
              <b>{t("linkDataProtection")}</b>
            </Link>
          </ParagraphInformation>
        </PanelItem>
      </CardContainer>
    </>
  );
};

export default UserInfo;
