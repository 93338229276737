import styled from "styled-components";
import { Grid } from "@material-ui/core";
import media from "styled-media-query";

export const GridStyled = styled(Grid).attrs({
  container: true,
})`
  padding: ${(props) => props.theme.getPanelSize(props.lang).padding};
  border-radius: 4px;
  min-height: 440px;
  display: inline-block;
  box-shadow: ${(props) => props.theme.shadows[6]};
  border: solid 1px ${(props) => props.theme.getColor("backgrounds", "light")};
  background-color: ${(props) => props.theme.getColor("common", "white")};

  ${media.lessThan("medium")`
    height: 550px;
  `}
`;
