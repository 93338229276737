import styled from "styled-components";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type FormLabelType = typeof FormLabel;
type FormControlLabelType = typeof FormControlLabel;

export const FormLabelStyled: FormLabelType = styled(FormLabel)`
  font-size: 10px;
  color: ${(props) => props.theme.getColor("common", "grey-medium")};

  &.Mui-focused {
    color: ${(props) => props.theme.getColor("common", "grey-medium")};
  }
`;

export const FormControlLabelStyled: FormControlLabelType = styled(
  FormControlLabel
)`
  color: ${(props) => props.theme.getColor("common", "grey-dark")};

  .MuiTypography-root {
    font-size: 12px;
  }

  .MuiIconButton-root {
    font-size: 16px;
  }
`;
