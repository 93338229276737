type Option = {
  label: string;
  value: string;
};

const dateFormats: Option[] = [
  { label: "20/12/2020", value: "DD/MM/YYYY" },
  { label: "2020/12/20", value: "YYYY-MM-DD" },
  { label: "12/20/2020", value: "MM/DD/YYYY" },
];

const radios: Option[] = [
  {
    label: "labelDotFormat",
    value: "BRAZILIAN",
  },
  {
    label: "labelCommaFormat",
    value: "ENGLISH",
  },
];

const languages: Option[] = [
  { label: "labelLanguagePortuguese", value: "pt-BR" },
  { label: "labelLanguageEnglish", value: "en-US" },
  { label: "labelLanguagePolish", value: "pl-PL" },
  { label: "labelLanguageSpanish", value: "es-AR" },
  { label: "labelLanguageRussian", value: "ru-RU" },
  { label: "labelLanguageVietnamese", value: "vi-VN" },
  { label: "labelLanguageThai", value: "th-TH" },
  { label: "labelLanguageChina", value: "zh-CN" },
  { label: "labelLanguageKorean", value: "ko-KR" },
  { label: "labelLanguageItalian", value: "it-IT" },
  { label: "labelLanguageHungarian", value: "hu-HU" },
];

export { dateFormats, radios, languages };
