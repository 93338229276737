import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptBr from "./Languages/pt-BR.json";
import enUs from "./Languages/en-US.json";
import esAR from "./Languages/es-AR.json";
import zhCN from "./Languages/zh-CN.json";
import itIT from "./Languages/it-IT.json";
import ruRU from "./Languages/ru-RU.json";
import thTH from "./Languages/th-TH.json";
import huHU from "./Languages/hu-HU.json";

const resources = {
  "en-US": {
    translation: enUs,
  },
  "pt-BR": {
    translation: ptBr,
  },
  "es-AR": {
    translation: esAR,
  },
  "zh-CN": {
    translation: zhCN,
  },
  "it-IT": {
    translation: itIT,
  },
  "ru-RU": {
    translation: ruRU,
  },
  "th-TH": {
    translation: thTH,
  },
  "hu-HU": {
    translation: huHU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en-US",
  lng: "pt-BR",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
