import styled from "styled-components";
import media from "styled-media-query";

import { DialogContent, DialogContentText, Grid } from "@material-ui/core";
import { Alert, FormControl } from "@mui/material";

export const DialogContentStyled = styled(DialogContent)`
  text-align: right;
`;

export const DialogContentTextStyled = styled(DialogContentText)`
  font-weight: bold;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const PanelItem = styled(Grid).attrs({ item: true })`
  .table {
    overflow: auto;
  }

  ${media.lessThan("medium")`
    display: flex;
    flex-direction: column;

    .first-input {
      margin-bottom: 15px;
    }
  `}
`;

export const Title = styled.h1`
  font-family: OpenSansBold;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  margin: 0;
`;

export const SubTitle = styled.p`
  padding-left: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  text-align: left;
  font-family: OpenSansBold;
`;

export const Close = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  ${media.lessThan("medium")`
    flex-direction: column;
    .firs-select {
      margin-bottom: 8px;
    }
  `}
`;

export const CustomFormSelect = styled(FormControl)`
  width: 230px;
  .MuiInputLabel-outlined {
    font-size: 0.75rem;
  }
`;

export const ButtonStyled = styled(Grid).attrs({ container: true })`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f2f2f2;
  height: 60px;
  ${media.lessThan("medium")`
    justify-content: center;
    background: none;
  `}
`;

export const ButtonContainer = styled.div`
  margin-right: 16px;

  ${media.lessThan("medium")`
    width: 100%;
    margin: 0 32px;
    height: 100%;
    > button {
      width: 100%;
      padding: 22px;
    }
  `}
`;

export const AlertStyled = styled(Alert)`
  display: flex;
  align-self: flex-end;
  width: 363px;
  height: 35px;
  border-radius: 30px 0px 0px 30px !important;
  margin-bottom: 7px;
  img {
    padding-right: 10px;
  }
  .MuiAlert-message {
    display: flex;
    align-items: center;
    margin: 9px 8px 8px 5px;
    font-family: "OpenSansRegular";
    color: #212325;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
  }
  .MuiAlert-action {
    display: flex;
    align-items: center;
  }
`;
