import { combineReducers } from "redux";
import { sampleReducer } from "./Sample";
import { userReducer } from "./User";
import { feedbackReducer } from "./Feedback";
import { userFarmReducer } from "./UserWithFarm";

export const Reducers = combineReducers({
  sample: sampleReducer,
  user: userReducer,
  feedback: feedbackReducer,
  userFarm: userFarmReducer,
});
