type Path = {
  path: string;
  name: string;
};

export const paths: Path[] = [
  { path: "/personal-data", name: "personalData" },
  { path: "/preferences", name: "preferences" },
  { path: "/farms", name: "yourBonds" },
];
