import React, { useEffect, useState } from "react";
import {
  DialogStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogContentTextStyled,
  DialogTitleStyled,
  Icon,
} from "./styles";
import Button from "../Button";
import errorIcon from "../../Images/error.svg";
import { useTranslation } from "react-i18next";

type Props = {
  success: boolean;
  error: boolean;
  setStateModal: (value: boolean) => void;
};

const NegativeFeedback = ({ success, error, setStateModal }: Props) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (success || error) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [success, error]);

  const handleClose = () => {
    setStateModal && setStateModal(open);
    setOpen(!open);
  };

  return (
    <DialogStyled
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleStyled id="alert-dialog-title">{""}</DialogTitleStyled>
      <DialogContentStyled>
        <Icon src={errorIcon} alt={"erro"} />
        <DialogContentTextStyled id="alert-dialog-description">
          {t("modalError")}
        </DialogContentTextStyled>
        <Button
          variant="primary"
          size="medium"
          label={t("modalButtonError")}
          onClick={handleClose}
        />
      </DialogContentStyled>
      <DialogActionsStyled></DialogActionsStyled>
    </DialogStyled>
  );
};

export default NegativeFeedback;
