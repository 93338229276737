import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Box, Chip } from "@mui/material";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  FormControlStyled,
  FormHelperTextStyled,
  InputLabelStyled,
  MenuItemStyled,
  SelectStyled,
} from "./styles";
import { translateNamesProducts, translateNameModules } from "../../Utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      textAlign: "left",
    },
  })
);

type Props = {
  value: string[];
  isModule?: boolean;
  multipleLabel: string;
  getValue: (value: Array<string>) => void;
  label: string;
  defaultValue?: any[];
  error?: boolean;
  textError?: string | string[];
};

export default function MultipleSelect({
  value,
  getValue,
  label,
  defaultValue = [],
  error,
  textError,
  isModule = false,
  multipleLabel,
}: Props) {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState(defaultValue);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (getValue) {
      getValue(event.target.value);
    }
  };

  return (
    <div>
      <FormControlStyled
        className={classes.formControl}
        variant="outlined"
        error={error}
        size="small"
      >
        <InputLabelStyled>{label}</InputLabelStyled>
        <SelectStyled
          className="teste"
          isModule={isModule}
          multiple
          value={personName}
          onChange={handleChange}
          defaultValue={defaultValue}
          input={<OutlinedInput label={label} />}
          renderValue={(selected: any) => (
            <>
              {personName.length === 1 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected.map((value: any) => (
                    <Chip
                      key={value}
                      label={
                        isModule
                          ? translateNameModules(value)?.label
                          : translateNamesProducts(value)
                      }
                      color="primary"
                      size="small"
                    />
                  ))}
                </Box>
              ) : (
                <span>
                  {personName.length} {t(multipleLabel)}{" "}
                </span>
              )}
            </>
          )}
          MenuProps={MenuProps}
        >
          {value.map((name: any) => (
            <MenuItemStyled isModule={isModule} key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText
                id="teste"
                primary={
                  isModule
                    ? translateNameModules(name).label
                    : translateNamesProducts(name)
                }
              />
            </MenuItemStyled>
          ))}
        </SelectStyled>
        <FormHelperTextStyled>{textError}</FormHelperTextStyled>
      </FormControlStyled>
    </div>
  );
}
