import React, { useCallback, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { PersonalData, Login, Farms, ListUsers } from "./Pages";
import Header from "./Components/Header";
import { useKeycloak } from "@react-keycloak/web";
import Preferences from "./Pages/Preferences";
import api, { Token } from "./Service/api";
import Feedback from "./Components/Feedback";
import { useDispatch, useSelector } from "react-redux";
import { User, PersistUser } from "./Actions/User";
import { RootState } from "./Store";
import { useTranslation } from "react-i18next";

const Routes = () => {
  const { initialized, keycloak } = useKeycloak();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSuccess, showFailure] = useSelector((state: RootState) => [
    state.feedback.showSuccess,
    state.feedback.showFailure,
  ]);

  const user = useSelector((state: RootState) => state.user.user);

  const handleUser = useCallback(
    (user: Partial<User>) => {
      dispatch(PersistUser(user));
    },
    [dispatch]
  );

  const handleGetUserInformation = async () => {
    const response = await api().get("/user-profile/");

    handleUser(response.data);
  };

  if (keycloak?.authenticated && !user.id) {
    Token.getInstance().setToken(keycloak.token ?? "");
    handleGetUserInformation();
  }

  useEffect(() => {
    if (user && user.language) {
      i18n.changeLanguage(user.language);
    }
  }, [user, i18n]);

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Header></Header>
      <Switch>
        <Route exact component={Login} path="/login" />
        {keycloak?.authenticated ? (
          <>
            <Route exact component={PersonalData} path="/personal-data" />
            <Route exact component={Preferences} path="/preferences" />
            <Route exact component={Farms} path="/farms" />
            <Route exact component={ListUsers} path="/farms/:id/users" />
          </>
        ) : (
          <Redirect to="/login"></Redirect>
        )}
      </Switch>
      <Feedback
        success={showSuccess}
        error={showFailure}
        labelSuccess={t("modalSuccess")}
        labelError={t("modalError")}
      />
    </Router>
  );
};

export default Routes;
