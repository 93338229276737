import React from "react";
import { GridStyled } from "./styles";

export type Type = "small" | "medium" | "large";

export type Props = {
  type: Type;
  children: any;
};

const Panel = ({ type = "medium", children }: Props) => {
  return <GridStyled lang={type}>{children}</GridStyled>;
};

export default Panel;
