import styled from "styled-components";

interface CardProps {
  isModule: boolean;
}

export const CardProduct = styled.div<CardProps>`
  background: ${(props) => (props.isModule ? "#3ebac2" : "#205fd4")};
  font-weight: ${(props) => (props.isModule ? 600 : 500)};
  color: #ffff;
  white-space: nowrap;
  margin: 0 2px;
  padding: 3px 6px;
  border-radius: 15px;
  font-family: "OpenSansRegular";
  font-size: 12px;
  margin-top: 1px;
  width: fit-content;
`;
