import React from "react";

import { DialogStyled } from "./styles";

export interface DialogModalProps {
  isOpen: boolean;
  isOpenEdit: boolean;
  children: React.ReactNode;
}

const DialogModal = (props: DialogModalProps) => {
  const { isOpen, isOpenEdit, children } = props;

  return (
    <>
      <DialogStyled
        open={isOpen ? isOpen : isOpenEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        <>{children}</>
      </DialogStyled>
    </>
  );
};

export default DialogModal;
