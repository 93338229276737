import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    a {
        text-decoration: none;
    }

    body{
        background: #f2f2f2
    }
`;
