import React from "react";
import { Paragraph, PanelContainer, PanelItem, SubTitle } from "../styles";
import Image from "../../../Images/dados-cloud.svg";
import { useTranslation } from "react-i18next";
type Props = {
  userName: string;
};

const UserInfo = ({ userName }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <PanelContainer>
        <PanelItem xs={12} sm={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img alt="dados-clod" width="150px" height="78px" src={Image} />
          </div>
        </PanelItem>
        <PanelItem  xs={12} sm={12} md={12}>
          <SubTitle>{userName}</SubTitle>
        </PanelItem>
        <PanelItem  xs={12} sm={12} md={12}>
          <Paragraph>
            {t("configurationsMessage")}
            <b> {t("configurationsMessage2")}</b>{" "}
          </Paragraph>
        </PanelItem>
      </PanelContainer>
    </>
  );
};

export default UserInfo;
