import React from "react";
import * as S from "./syles";
import { IoInformationCircleSharp } from "react-icons/io5";
import i18n from "i18next";

interface ModalProps {
  isShow: boolean;
}

const InfoModal = ( isShow: ModalProps) => {
  return (
    isShow && (
      <S.Container>
        <S.Content>
          <IoInformationCircleSharp size={16} color="#205fd4" />
          <p>
            <strong>{i18n.t("tooltipModalTitle")}</strong>
            {i18n.t("tooltipModalSubTitle")}
          </p>
        </S.Content>
      </S.Container>
    )
  );
};

export default InfoModal;
