import React from "react";
import { PanelItem, PanelContainer } from "../styles";
import InputField from "../../../Components/InputField";
import { PersonalDataModel } from "../index";
import { FormikErrors } from "formik";
import { useTranslation } from "react-i18next";

type Props = {
  values: PersonalDataModel;
  setValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<PersonalDataModel> | void>;
  errors: FormikErrors<PersonalDataModel>;
};

const PersonalInformation = ({ values, setValue, errors }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <PanelContainer>
        <PanelItem xs={12} lg={12} sm={12} id="panelItemStyled">
          <InputField
            textId={t("fullName")}
            setValue={(value: string) => setValue("name", value)}
            value={values.name}
            label={t("fullName") + " *"}
            key="name"
            error={!!errors.name}
            errorMessage={errors.name}
          ></InputField>
        </PanelItem>
      </PanelContainer>
      <PanelContainer>
        <PanelItem xs={12} sm={6} id="panelItemStyled">
          <InputField
            setValue={(value: string) => setValue("email", value)}
            value={values.email}
            label={t("loginMail")}
            textId={t("loginMail")}
            key="email"
            error={!!errors.email}
            errorMessage={errors.email}
            disabled={true}
          ></InputField>
        </PanelItem>
        <PanelItem item xs={12} sm={6} id="panelItemStyled">
          <InputField
            setValue={(value: string) => setValue("secondary_email", value)}
            value={values.secondary_email}
            label={t("alternativeEmail")}
            textId={t("alternativeEmail")}
            key="secondary_email"
          ></InputField>
        </PanelItem>
      </PanelContainer>
      <PanelContainer>
        <PanelItem xs={12} sm={4} id="panelItemStyled">
          <InputField
            setValue={(value: string) =>
              setValue("phone", value.match(/[0-9]+/g)?.join("") ?? value)
            }
            value={values.phone}
            label={t("phone")}
            textId={t("phone")}
            key="phone"
            error={!!errors.phone}
            errorMessage={errors.phone}
          ></InputField>
        </PanelItem>
        <PanelItem xs={12} sm={4} id="panelItemStyled">
          <InputField
            setValue={(value: string) =>
              setValue("cell_phone", value.match(/[0-9]+/g)?.join("") ?? value)
            }
            value={values.cell_phone}
            label={t("cellPhone")}
            textId={t("cellPhone")}
            key="cell_phone"
            error={!!errors.cell_phone}
            errorMessage={errors.cell_phone}
          ></InputField>
        </PanelItem>
        <PanelItem xs={12} sm={4} id="panelItemStyled">
          <InputField
            setValue={(value: string) =>
              setValue("whatsapp", value.match(/[0-9]+/g)?.join("") ?? value)
            }
            value={values.whatsapp}
            label={t("Whatsapp")}
            textId={t("Whatsapp")}
            key="whatsapp"
            error={!!errors.whatsapp}
            errorMessage={errors.whatsapp}
          ></InputField>
        </PanelItem>
      </PanelContainer>
    </>
  );
};

export default PersonalInformation;
