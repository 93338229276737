import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Store";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { StylesProvider } from "@material-ui/core/styles";
import GlobalStyle from "./Styles/global";
import { theme, styledTheme } from "./Styles/theme";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Utils/Auth/keycloak";
import "./i18n";

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={{ ...theme, ...styledTheme }}>
          <Provider store={store}>
            <GlobalStyle />
            <Routes />
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
