import { Grid, Avatar, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { withStyles } from "@material-ui/core/styles";
import media from "styled-media-query";

export const ContainerHeader = styled(Grid).attrs({
  container: true,
})`
  ${media.lessThan("medium")`
    max-width: none;
    display: flex;
    justify-content: center;
    height: auto;
  `}
  max-width: 100%;
  height: 48px;
  background: ${(props) => props.theme.getColor("common", "white")};
  box-shadow: ${(props) => props.theme.shadows[7]};
  display: flex;
`;

export const LogoContainer = styled(Grid).attrs({ item: true })`
  ${media.lessThan("medium")`
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  `}
  position: relative;
  max-width: 208px;
  padding: 16px 35.7px 14px 24px;
  box-shadow: ${(props) => props.theme.shadows[7]};
  max-height: 48px;
`;

export const ImageLogo = styled.img`
  width: 148px;
  height: auto;
`;
export const HeaderItem = styled(Grid).attrs({ item: true })`
  ${media.lessThan("medium")`
    display: flex;
    justify-content: center;
  `}
`;

export const HelpItem = styled(Grid).attrs({ item: true })`
  ${media.lessThan("medium")`
    display: none;
  `}
  vertical-align: bottom;
  height: 100%;
  width: 79px;
  text-align: center;
  border-right: 1px solid
    ${(props) => props.theme.getColor("common", "grey-dark-less-opacity")};
  border-left: 1px solid
    ${(props) => props.theme.getColor("common", "grey-dark-less-opacity")};
  display: inline-block;
`;

export const UserItem = styled(Grid).attrs({ item: true })`
  text-align: center;
  display: inline-block;

  img {
    margin: 8px 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
  }

  ${media.lessThan("medium")`
    display: none;
    
  `}
`;

export const IconHelp = styled(HelpOutlineIcon)`
  color: ${(props) => props.theme.getColor("feedback", "alert")};
  margin-top: 12px;
`;

export const HelpText = styled.span`
  color: ${(props) => props.theme.getColor("feedback", "alert")};
  font-size: 12px;
  vertical-align: super;
  font-family: OpenSansBold;
`;

export const IconUser = styled(AccountCircleIcon)`
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin: 8px 10px;
  color: ${(props) => props.theme.getColor("common", "grey-medium")};
`;

export const IconUserLogout = styled(AccountCircleIcon)`
  vertical-align: middle;
  color: ${(props) => props.theme.getColor("common", "grey-medium")};
`;

export const LogoutButton = styled.div`
  cursor: pointer;
  color: #205fd4;
  margin-top: 10px;
`;

export const LogoutImage = styled.img`
  vertical-align: middle;
  margin-right: 8px;
`;

export const MenuStyled = styled.div`
  background: ${(props) => props.theme.getColor("common", "grey-light")};
  padding: 20px;
  margin-top: 10px;
  padding-right: 30px;
`;

export const MenuItem = styled.div`
  background: #fff;
  box-shadow: ${(props) => props.theme.shadows[7]};
  padding: 5px;
  border-radius: 5px;
  display: flex;
  width: 100%;
  div {
    margin-right: 5px;
    width: 60px;
    height: 60px;
    display: inline-flex;
    img {
      border-radius: 50%;
    }
  }
`;

export const UserInfos = styled.span`
  vertical-align: middle;
  font-size: 12px;
  display: inline-block;
  text-align: left;
`;

export const AvatarStyled = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin-right: 5px;
  font-size: 1.4rem;
  display: inline-flex;
`;

export const Link = styled.a`
  width: 90px;
  max-height: 16px;
  height: 32px;
  border-radius: 4px;
  background: ${(props) => props.theme.getColor("backgrounds", "light")};
  display: inline-block;
  text-align: center;
  padding: 8px;
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  font-weight: bold;
  margin-top: 5px;
  box-shadow: ${(props) => props.theme.shadows[3]};
  margin-bottom: 5px;
`;

export const ExitLabel = styled.span`
  color: ${(props) => props.theme.getColor("common", "grey-dark")};
  font-size: 14px;
  font-weight: bold;
`;
export const Triangle = styled.div`
  display: inline-block;
  margin-right: 50px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid
    ${(props) => props.theme.getColor("common", "grey-light")};
  position: absolute;
  right: -50px;
`;

export const TooltipArrow = withStyles((theme) => ({
  arrow: {
    color: "#fff",
  },
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    width: 152,
    border: "1px solid #dadde9",
    fontSize: 10,
    lineHeight: 1.6,
  },
}))(Tooltip);
