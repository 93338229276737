import React, { useMemo } from "react";
import { PanelItem, PanelContainer, RadioContainer } from "../styles";
import { ConfigurationsDataModel } from "../index";
import { FormikErrors } from "formik";
import CustomSelect from "../../../Components/CustomSelect";
import RadioGroup from "../../../Components/RadioGroup";
import { radios, dateFormats, languages } from "../constants";
import { useTranslation } from "react-i18next";

type Props = {
  values: ConfigurationsDataModel;
  setValue: (
    field: string,
    value: string,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<ConfigurationsDataModel> | void>;
  errors: FormikErrors<ConfigurationsDataModel>;
};

const Infos = ({ values, setValue, errors }: Props) => {
  const { t } = useTranslation();

  const radiosMap = useMemo(() => {
    return radios.map((radio) => {
      return { label: t(radio.label), value: radio.value };
    });
  }, [t]);

  const languagesMap = useMemo(() => {
    return languages.map((language) => {
      return { label: t(language.label), value: language.value };
    });
  }, [t]);

  return (
    <>
      <PanelContainer>
        <PanelItem sm={6} xs={12} id="panelItemStyled">
          <CustomSelect
            helperText=""
            value={values.language}
            onChange={(value: string) => {
              setValue("language", value);
            }}
            label={t("language")}
            options={languagesMap}
            textId={t("language")}
          />
        </PanelItem>
        <PanelItem sm={6} xs={12}>
          <CustomSelect
            helperText=""
            value={values.date_format}
            onChange={(value: string) => setValue("date_format", value)}
            label={t("dateFormat")}
            options={dateFormats}
            textId={t("dateFormat")}
          />
        </PanelItem>
      </PanelContainer>
      <PanelContainer style={{ marginTop: "1rem" }}>
        <RadioContainer>
          <RadioGroup
            textId={t("numberFormat")}
            value={values.decimal_separator}
            onChange={(value: string) => setValue("decimal_separator", value)}
            label={t("numberFormat")}
            radios={radiosMap}
          />
        </RadioContainer>
      </PanelContainer>
    </>
  );
};

export default Infos;
