const standardAction = "FEEDBACK::";

export const SUCCESS = `${standardAction}SUCCESS`;
export const FAILURE = `${standardAction}FAILURE`;

export const Success = () => ({
  type: SUCCESS,
});

export const Failure = () => ({
  type: FAILURE,
});
