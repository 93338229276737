import { createTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows";
import {
  getColor,
  getPaddingByButtonSize,
  getFontFamily,
  getPanelSize,
} from "./Variables";

const theme = createTheme({
  palette: {
    primary: {
      main: "#205FD4",
    },
    secondary: {
      main: "#063097",
    },
  },
  breakpoints: {
    values: {
      xs: 540,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  shadows: [
    "none",
    "0.0px 0.0px 6.0px 0px rgba(113, 118, 122, 0.1)",
    "2.0px 2.0px 4.0px 0px rgba(33, 35, 37, 0.08)",
    "2.0px 2.0px 4.0px 0px rgba(33, 35, 37, 0.15)",
    "4.0px 4.0px 5.0px 0px rgba(33, 35, 37, 0.15)",
    "6.0px 6.0px 8.0px 0px rgba(33, 35, 37, 0.15)",
    "2px 2px 4px 0 rgba(33, 35, 37, 0.08)",
    "2px 2px 4px 0 rgba(33, 35, 37, 0.15)",
    ...Array(17).fill("none"),
  ] as Shadows,
});

const styledTheme = {
  getColor,
  getPaddingByButtonSize,
  getFontFamily,
  getPanelSize,
};

export { theme, styledTheme };
