import React, { useEffect, useRef, useCallback } from "react";
import Panel from "../../Components/Panel";
import Button from "../../Components/Button";
import {
  Title,
  PanelItem,
  PanelContainer,
  Divider,
  PageTitle,
  UserInfoContent,
  ButtonContent,
} from "./styles";
import Infos from "./Infos";
import UserInfo from "./UserInfo";
import { useFormik } from "formik";
import Yup from "../../Utils/Validations";
import { useDispatch, useSelector } from "react-redux";
import { SaveUser, User, PersistUser } from "../../Actions/User";
import { RootState } from "../../Store";
import { useTranslation } from "react-i18next";
export type ConfigurationsDataModel = {
  language: string;
  date_format: string;
  decimal_separator: string;
};

const Preferences = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.user);
  const formik = useFormik<ConfigurationsDataModel>({
    initialValues: {
      language: "",
      date_format: "",
      decimal_separator: "",
    },
    onSubmit: (values) => {},
    validationSchema: Yup.object().shape({
      language: Yup.string().required(t("required")),
      date_format: Yup.string().required(t("required")),
      decimal_separator: Yup.string().required(t("required")),
    }),
  });

  const { values, setFieldValue, errors, isValid, setValues } = formik;
  const firstLoad = useRef(true);

  const handleSave = useCallback(() => {
    dispatch(SaveUser());
  }, [dispatch]);

  const handleInfos = useCallback(
    (user: Partial<User>) => {
      dispatch(PersistUser(user));
    },
    [dispatch]
  );

  useEffect(() => {
    if (user.id && firstLoad.current) {
      const model: ConfigurationsDataModel = {
        language: user.language,
        date_format: user.date_format,
        decimal_separator: user.decimal_separator,
      };
      setValues(model);
      firstLoad.current = false;
    }
  }, [user, setValues, firstLoad]);

  useEffect(() => {
    handleInfos(values);
  }, [values, handleInfos]);
  return (
    <PanelContainer>
      <PageTitle id="titleMobile">{t("preferences")}</PageTitle>
      <PanelItem id="painel-medium" xs={12} sm={8} lg={8}>
        <Panel type="medium">
          <Title>{t("platformConfigurations")}</Title>
          <Infos errors={errors} values={values} setValue={setFieldValue} />
          <PanelItem xs={12} sm={12} lg={12}>
            <Divider></Divider>
          </PanelItem>
          <PanelContainer>
            <ButtonContent style={{ textAlign: "right" }} sm={12} xs={12}>
              <Button
                disabled={!isValid}
                variant="primary"
                size="medium"
                label={t("save")}
                onClick={handleSave}
                id={"buttonFullMobile"}
              />
            </ButtonContent>
          </PanelContainer>
        </Panel>
      </PanelItem>
      <PanelItem />

      <PanelItem id="painel-small" xs={12} sm={12} md={4} lg={4}>
        <UserInfoContent>
          <UserInfo userName={user.name}></UserInfo>
        </UserInfoContent>
      </PanelItem>
    </PanelContainer>
  );
};

export default Preferences;
