import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const TextFieldStyled = styled(TextField)`
  .MuiFormLabel-root {
    color: #71767a;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-image-source: linear-gradient(
        to top,
        ${(props) => props.theme.getColor("primary", "primary")}
      ),
      ${(props) => props.theme.getColor("tertiary")};
    border-image-slice: 1;
  }

  .MuiOutlinedInput-input {
    font-size: 12px;
    padding: 12px 8px 10px;
  }
  .MuiInputBase-root.Mui-disabled {
    background: #f2f2f2;
  }

  label.MuiInputLabel-outlined {
    /* transform: translate(14px, 9px) scale(1); */
    display: inline-block;
    margin: -7px;
  }

  .MuiInputLabel-formControl {
    font-size: 12px;
    /* position: relative; */
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 9px;
    padding-left: 9px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 8px;
  }

  .MuiSvgIcon-root {
    fill: #212325;
    width: 0.8em;
    vertical-align: middle;
  }
`;

export const TouchableFake = styled.span`
  cursor: pointer;
`;
