import React, { useEffect, useRef, useCallback } from "react";
import Panel from "../../Components/Panel";
import Button from "../../Components/Button";
import {
  Title,
  PanelItem,
  PanelContainer,
  Divider,
  PageTitle,
  ButtonContent,
  UserInfoContent,
} from "./styles";
import PersonalInformation from "./PersonalInformation";
import UserInfo from "./UserInfo";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Yup from "../../Utils/Validations";
import { SaveUser, User, PersistUser } from "../../Actions/User";
import { RootState } from "../../Store";
import { useTranslation } from "react-i18next";

export type PersonalDataModel = {
  name: string;
  email: string;
  secondary_email: string;
  phone: string;
  cell_phone: string;
  gender: string;
  whatsapp: string;
};

const PersonalData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.user);
  const formik = useFormik<PersonalDataModel>({
    initialValues: {
      name: "",
      email: "",
      secondary_email: "",
      phone: "",
      cell_phone: "",
      gender: "",
      whatsapp: "",
    },
    onSubmit: (values) => {},
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("required")),
      email: Yup.string().email(t("invalidEmail")).required(t("required")),
      secondary_email: Yup.string().email(t("invalidEmail")),
      phone: Yup.string().onlyNumbers(),
      cell_phone: Yup.string().onlyNumbers(),
      whatsapp: Yup.string().onlyNumbers(),
    }),
  });

  const { values, setFieldValue, errors, isValid, setValues } = formik;
  const firstLoad = useRef(true);

  const handleSave = useCallback(() => {
    dispatch(SaveUser());
  }, [dispatch]);

  const handleInfos = useCallback(
    (user: Partial<User>) => {
      dispatch(PersistUser(user));
    },
    [dispatch]
  );

  useEffect(() => {
    if (user?.id && firstLoad.current) {
      const model: PersonalDataModel = {
        name: user?.name,
        email: user?.email,
        secondary_email: user?.secondary_email ?? "",
        phone: user?.phone ?? "",
        cell_phone: user?.cell_phone ?? "",
        gender: user?.gender ?? "",
        whatsapp: user?.whatsapp ?? "",
      };

      setValues(model);
      firstLoad.current = false;
    }
  }, [user, setValues]);

  useEffect(() => {
    handleInfos(values);
  }, [values, handleInfos]);

  return (
    <PanelContainer>
      <PageTitle id="titleMobile">{t("yourProfile")}</PageTitle>
      <PanelItem id="painel-medium" xs={12} sm={12} md={8} lg={8}>
        <Panel type={"medium"}>
          <Title>{t("personnalData")}</Title>
          <PersonalInformation
            errors={errors}
            values={values}
            setValue={setFieldValue}
          />
          <PanelItem sm={12} xs={12} lg={12}>
            <Divider></Divider>
          </PanelItem>
          <PanelContainer>
            <ButtonContent style={{ textAlign: "right" }} sm={12} xs={12}>
              <Button
                disabled={!isValid}
                variant={"primary"}
                size={"large"}
                label={t("save")}
                onClick={handleSave}
                id={"buttonFullMobile"}
              ></Button>
            </ButtonContent>
          </PanelContainer>
        </Panel>
      </PanelItem>
      <PanelItem id="painel-small" xs={12} sm={12} md={4} lg={4}>
        <UserInfoContent>
          <UserInfo gender={user?.gender} userName={user?.name}></UserInfo>
        </UserInfoContent>
      </PanelItem>
    </PanelContainer>
  );
};

export default PersonalData;
