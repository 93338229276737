import { SUCCESS, FAILURE } from "../../Actions/Feedback";

type Feedback = {
  showSuccess: boolean;
  showFailure: boolean;
};

const initialState: Feedback = {
  showSuccess: false,
  showFailure: false,
};

type Action = {
  type: any;
};

export const feedbackReducer = (
  state = initialState,
  action: Action = { type: "" }
) => {
  const { type } = action;
  switch (type) {
    case SUCCESS:
      return {
        ...state,
        showSuccess: !state.showSuccess,
      };
    case FAILURE:
      return {
        ...state,
        showFailure: !state.showFailure,
      };
    default:
      return state;
  }
};
