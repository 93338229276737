import { all, takeLatest, put, debounce } from "redux-saga/effects";
import { SAGA_COUNT, COUNT, SAGA_COUNT_DEBOUNCE } from "../../Actions/Sample";

function* sagaCount() {
  yield put({ type: COUNT });
}

export function* watchSampleScreen(): any {
  yield all([
    yield takeLatest(SAGA_COUNT, sagaCount),
    yield debounce(2000, SAGA_COUNT_DEBOUNCE, sagaCount),
  ]);
}
