const standardAction = "USERFARM::";

export type UserFarm = {
  id: string;
  name: string;
  email: string;
  products: [];
  whatsapp: string;
};

export const LISTUSERFARM = `${standardAction}LISTUSERFARM`;
export const LISTUSERFARMDATA = `${standardAction}LISTUSERFARMDATA`;

export const listUserFarm = () => ({
  type: LISTUSERFARM,
});

export const listUserFarmData = (value: UserFarm) => ({
  type: LISTUSERFARMDATA,
  payload: value,
});
