import axios from "axios";
import env from "react-dotenv";
export class Token {
  private static instance: Token;
  private token: string = "";

  public setToken(token: string) {
    this.token = token;
  }

  public getToken() {
    return this.token;
  }

  public static getInstance(): Token {
    if (!Token.instance) {
      Token.instance = new Token();
    }

    return Token.instance;
  }
}

const api = () => {
  return axios.create({
    baseURL: env.REACT_APP_BASE_URL || "",
    headers: {
      Authorization: Token.getInstance().getToken(),
    },
  });
};

export default api;
