import { all } from "redux-saga/effects";
import { watchSampleScreen } from "./Sample";
import { watchUserScreen } from "./User";
import { watchFeedbackScreen } from "./Feedback";

export default function* Saga() {
  yield all([
    watchSampleScreen(),
    watchUserScreen(),
    watchFeedbackScreen(),
  ]);
}
