import { UserFarm, LISTUSERFARMDATA } from "../../Actions/UserFarm";

export type reducerUserFarm = {
  userFarm: UserFarm[];
};

const initialState = {
  userFarm: [],
};

type Action = {
  type: any;
  payload: [];
};

export const userFarmReducer = (
  state = initialState,
  action: Action = { type: "", payload: [] }
) => {
  const { type, payload } = action;
  switch (type) {
    case LISTUSERFARMDATA:
      return {
        ...state,
        userFarm: payload,
      };
    default:
      return state;
  }
};
